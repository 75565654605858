import { useCallback, useState } from "react";
import { TextField } from "@mui/material";

const DefaultPromptTabPanel = ({ data = "", onAddSchema = () => {} }) => {
  const [state, setState] = useState(() => data);

  const handleChange = useCallback(
    ({ target: { value } }) => setState(value),
    []
  );

  const handleOnAddPrompt = useCallback(
    () => onAddSchema(state),
    [state, onAddSchema]
  );

  return (
    <>
      <div className="description">Add and edit prompt to node.</div>
      <TextField
        id="outlined-multiline-static"
        multiline
        label="Prompt"
        rows={16}
        style={{
          fontSize: "14px",
          border: "1px solid #ccc",
          borderRadius: "0px",
          display: "flex",
        }}
        value={state}
        onChange={handleChange}
      />
      <button
        className="btn btn-square btn-dark"
        style={{
          marginTop: "10px",
          borderRadius: "0px",
          border: "1px solid",
          width: "100%",
        }}
        onClick={handleOnAddPrompt}
      >
        Add Prompt
      </button>
    </>
  );
};

export default DefaultPromptTabPanel;
