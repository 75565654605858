import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SchemaIcon from "@mui/icons-material/SchemaOutlined";
import background from "../../imgs/logo.png";

const Item = ({ title, to, icon, selected, setSelected, linkLocation }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.primary[400],
      }}
      onClick={() => {
        if (linkLocation) {
          window.open(linkLocation, "_blank", "noreferrer");
        } else {
          setSelected(title);
        }
      }}
      icon={icon}
      routerLink={linkLocation ? undefined : <Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MySidebar = () => {
  const [isCollapsed] = useState(false);
  const [selected, setSelected] = useState("ChatBot");

  return (
    <Box
      className="sidebarBox"
      sx={{
        "& .sidebar-inner": {
          background: `#1e3d59 !important`,
          color: "#ffffff !important",
        },
        "& .icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .inner-item:hover": {
          color: "#ffffff !important",
        },
        "& .menu-item.active": {
          width: "95%",
          color: "#fe6a6b !important",
          borderRadius: "15px",
          margin: "0px 5px 0px 5px",
        },
        "& .menu-item.active .menu-anchor": {
          width: "95%",
          color: "#fe6a6b !important",
          // background: `#fe6a6b88 !important`,
          background: `#1e3d59 !important`,
          borderRadius: "15px",
          margin: "0px 5px 0px 5px",
        },
        "& .menu-item:hover": {
          width: "95%",
          color: "#fe6a6b !important",
          // background: `#fe6a6b33 !important`,
          background: `#1e3d59 !important`,
          borderRadius: "15px",
          margin: "0px 5px 0px 5px",
        },
        "& .menu-item:hover .menu-anchor": {
          width: "95%",
          color: "#fe6a6b !important",
          // background: `#fe6a6b33 !important`,
          background: `#1e3d59 !important`,
          borderRadius: "15px",
          margin: "0px 5px 0px 5px",
        },
      }}
    >
      <Sidebar collapsed={isCollapsed} className="appSidebar">
        <Menu>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            // onClick={() => {setIsCollapsed(!isCollapsed); handleSidebarCollapsed(isCollapsed);}}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <div
                  style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    width: "150px",
                    height: "40px",
                  }}
                ></div>
                {/* <Typography variant="h3" color={colors.primary[400]}>
                  AI Embodied
                </Typography> */}
                {/* <IconButton onClick={() => {setIsCollapsed(!isCollapsed); handleSidebarCollapsed(isCollapsed);}}>
                  <MenuOutlinedIcon />
                </IconButton> */}
              </Box>
            )}
          </MenuItem>
          <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
            Navigation
          </Typography>
          <Box paddingLeft={isCollapsed ? undefined : "0"}>
            <Item
              title="Templates"
              to="/dashboard"
              icon={<DashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Schema Builder"
              to="/formBuilder"
              icon={<SchemaIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Test"
              to="/chatbot"
              icon={<MessageOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Build"
              to="/settings"
              icon={<AccountTreeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Logout"
              to="/logout"
              icon={<LogoutIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
            title="Signup"
            to="/signup"
            icon={<LogoutIcon />}
            selected={selected}
            setSelected={setSelected}
          /> */}
            {/* <Item
              title="Contacts Information"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
          <Typography variant="h6" sx={{ m: "15px 0 5px 20px" }}>
            More Resources
          </Typography>
          <Box paddingLeft={isCollapsed ? undefined : "0"}>
            <Item
              title="Documentation"
              to="/documentation"
              linkLocation="https://aiembodied.notion.site/API-Documentation-db4bd9c3c63e47c3b062830ebb1e2890"
              icon={<ArticleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Item
              title="Tutorial"
              to="/tutorial"
              linkLocation="https://www.google.com"
              icon={<ForumOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Support"
              to="/support"
              linkLocation="mailto:contact@aiembodied.com"
              icon={<SupportAgentOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MySidebar;
