import { configureStore } from "@reduxjs/toolkit";
import messagesReducer from "./storage/MessagesSlice";
import infoCardReducer from "./storage/InfoCardSlice";
import authUserReducer from "./storage/AuthUserSlice";
import appuserSettingsReducer from "./storage/AppUserSettingsSlice";

export const store = configureStore({
  reducer: {
    messages: messagesReducer,
    infoCards: infoCardReducer,
    authUser: authUserReducer,
    appuserSettings: appuserSettingsReducer,
  },
});
