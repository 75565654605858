import React from "react";
import "../../styles.scss";
import BotMessage from "./Message";
import { useSelector } from "react-redux";

const BotMessages = () => {
  const { messages } = useSelector((state) => state.messages);
  return (
    <div className="botMessages">
      {messages.map((m, index) => (
        <BotMessage message={m} key={index} />
      ))}
    </div>
  );
};

export default BotMessages;
