import Field from "../Field";

const FieldGroup = ({
  fields,
  state,
  errors = {},
  onChange,
  requiredFields = [],
  FieldComponent = Field,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {Object.entries(fields).map(([name, fieldType], index) => {
        const isRequired = requiredFields.includes(name);

        const { type, ...additionalFieldProps } = fieldType;

        const fieldProps = {
          // TODO: convert to type toCamelCase
          type: type.toLowerCase(),
          name,
          value: state[name] || "",
          required: isRequired,
          error: errors?.[name] || "",
          onChange,
          ...additionalFieldProps,
        };
        const key = `${name}-${index}`;

        return <FieldComponent key={key} {...fieldProps} />;
      })}
    </div>
  );
};

export default FieldGroup;
