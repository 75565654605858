import { Grid, Typography } from "@mui/material";

import { fieldsPattern } from "./constants";

const PatternsTabPanel = ({ handleDragStart, handleDragEnd }) => {
  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      justifyContent="flex-start"
      paddingTop={2}
      spacing={2}
    >
      {fieldsPattern.map(({ type, patterns }) => (
        <Grid key={`pattern-${type}`} item xs={6}>
          <Typography variant="h5" textAlign="center">
            {type.toUpperCase()}
          </Typography>
          {patterns.map((pattern) => (
            <div
              key={`pattern-${pattern}`}
              onDragStart={(e) =>
                handleDragStart(e, { componentType: "prop", type, pattern })
              }
              draggable="true"
              onDragEnd={handleDragEnd}
              className="dndnode input"
            >
              {pattern}
            </div>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default PatternsTabPanel;
