export const parseTable = (table, data = {}) =>
  Object.entries(table).reduce((acc, [name]) => {
    acc[name] = data[name] ? data[name] : "";
    return acc;
  }, {});

/////////////////// Validators ////////////////////////////
export const hasValue = (val) => {
  if (Array.isArray(val)) {
    return !!val.length;
  }
  return !!val;
};

export const requiredFieldErrorTemplate = (fieldName) =>
  `${fieldName} is required`;

export const requiredFieldValidations = [
  hasValue,
  (fieldName) => requiredFieldErrorTemplate(fieldName),
];

// [fieldName, [validatorFn, errorMsgFn]]
export const getRequiredFieldValidatorsMap = (requiredFields) =>
  requiredFields.map((fieldName) => [fieldName, requiredFieldValidations]);

export const validateFields = (data, validatorsMap) => {
  return validatorsMap.reduce((acc, [fieldName, [validatorFn, errMsgFn]]) => {
    const val = data[fieldName];
    const hasError = !validatorFn(val);

    if (hasError) {
      acc[fieldName] = errMsgFn(fieldName);
    }
    return acc;
  }, {});
};

//////////////////// Validators End //////////////////////

/////////////////// Form helpers ////////////////////////
export const applyFormExtensions = (formFields, fieldsExtensions) =>
  Object.entries(formFields).reduce((acc, [name, props]) => {
    acc[name] = fieldsExtensions[name] ? fieldsExtensions[name](props) : props;
    return acc;
  }, formFields);
