import "../../styles.scss";
import ChatInput from "./ChatInput";
import BotMessages from "./Messages";

const ChatList = () => {
  return (
    <div className="chatList">
      <BotMessages />
      <ChatInput />
    </div>
  );
};

export default ChatList;
