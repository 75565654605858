import { useState } from "react";
import "../../styles.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ChatInfoCard from "./ChatInfoCard";
import { Box, Typography, Tab, Tabs, Button, Grid } from "@mui/material";
import LayoutFlow from "../graph/ChatSideBarGraphLayout";
import { useLocation } from "react-router-dom";
import { resourceTree } from "../graph/ReactFlowToResourceGraph";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatSideBar = () => {
  const { infoCards } = useSelector((state) => state.infoCards);
  // var jsonToSend = getJsonToKeys(appuserSettings);
  const location = useLocation();

  var resourceGraph = {
    name: "Start Building",
    description:
      "Go to the builder page to start building your resource graph!",
    resources: [
      {
        id: 1,
        type: "text input",
        name: "input",
        config: {},
      },
    ],
    connections: [],
  };

  if (location.state) {
    if (location.state.resourceGraph) {
      resourceGraph = location.state.resourceGraph;
    }
    if (location.state.reactFlowGraph) {
      resourceGraph = resourceTree(location.state.reactFlowGraph);
    }
  }

  const YAML = require("json-to-pretty-yaml");
  var resourceGraphList = { ...resourceGraph };
  delete resourceGraphList.connections;

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      className="chatSideBar"
      display="flex"
      flexDirection="column"
      style={{ overflow: "scroll" }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="resource graph diagram"
        >
          <Tab label="Resources" {...a11yProps(0)} />
          <Tab label="Layout" {...a11yProps(1)} />
          <Tab label="Output" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <pre className="pre">{YAML.stringify(resourceGraphList)}</pre>
        <Grid sx={{ position: "absolute", bottom: 20 }}>
          <Grid item xs={1} />
          <Grid item xs={10} style={{ display: "flex", gap: "1rem" }}>
            <Button
              className="btn btn-square btn-light border border-dark rounded-0 mt-4"
              // onClick={() => setNodePrompt("")}
            >
              Save
            </Button>
            <Link
              to="/settings"
              state={{
                resourceGraph: resourceGraph,
              }}
              className="btn btn-square btn-dark rounded-0 mt-4"
            >
              Edit
            </Link>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LayoutFlow resourceGraph={resourceGraph}></LayoutFlow>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box className="chatSideBarCards" mt="5px">
          {infoCards.map((m, index) => (
            <ChatInfoCard infoCard={m} key={index} />
          ))}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default ChatSideBar;
