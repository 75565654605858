import { TextField } from "@mui/material";

import Select from "../Inputs/Select";
import Enum from "../Inputs/Enum";
import MultiSelect from "../Inputs/MultiSelect";

const inputFieldMap = {
  int: TextField,
  string: TextField,
  date: TextField,
  enum: Enum,
  select: Select,
  multiselect: MultiSelect,
};

const Field = ({
  type,
  label: labelProp = "",
  error = "",
  helperText = "",
  ...props
}) => {
  const InputField = inputFieldMap[type];
  const label = labelProp || props.name;
  const hasError = !!error;
  helperText = hasError ? error : helperText;

  return (
    <InputField
      variant="standard"
      label={label}
      error={hasError}
      helperText={helperText}
      {...props}
    />
  );
};

export default Field;
