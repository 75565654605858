import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


export const firebaseConfig = {
    apiKey: "AIzaSyCK8Ks3Ji-X4zgGGikNVgFz0QjyInGKVCI",
    authDomain: "brevisent-add25.firebaseapp.com",
    databaseURL: "https://brevisent-add25.firebaseio.com",
    projectId: "brevisent-add25",
    storageBucket: "brevisent-add25.appspot.com",
    messagingSenderId: "756694355255",
    appId: "1:756694355255:web:38f3ef23357dcebafa1eb2"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
