import ChatSideBar from "./ChatSidebar";
import ChatList from "./ChatList";
import "../../styles.scss";
import ReactGA from "react-ga";

const ChatBot = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="pageContainer">
      <ChatList />
      <ChatSideBar />
    </div>
  );
  // return (<Box display="flex" width="auto" flexDirection="column" height="90vh">
  //    <ChatContainer>
  //     <MessageList>
  //       <Message model={{
  //                message: "Hello my friend",
  //                sentTime: "just now",
  //                sender: "Joe"
  //                }} />
  //                <Message model={{
  //                 message: "Hello my friend",
  //                 sentTime: "just now",
  //                 sender: "Joe",
  //                 direction: "outgoing"
  //                 }} />
  //       </MessageList>
  //     <MessageInput placeholder="Type message here" />
  //   </ChatContainer>
  // </Box>
  // );
};

export default ChatBot;
