export const initialNodes = (data) => {
  const position = { x: 0, y: 0 };

  const nodes = data.resources.map((item, index) => ({
    id: item.id.toString(),
    data: { label: item.name },
    type: item.type,
    position,
  }));

  return nodes;
};

export const initialEdges = (data) => {
  const edgeType = "smoothstep";

  const edges = data.connections.map((item, index) => ({
    id: item.index,
    source: item.source,
    target: item.target,
    type: edgeType,
    animated: true,
  }));

  return edges;
};
