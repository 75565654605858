import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MySidebar from "./scenes/global/MySidebar";
import Dashboard from "./scenes/dashboard";
import Login from "./scenes/login";
import Signup from "./scenes/signup";
import Settings from "./scenes/settings";
import Logout from "./scenes/global/Logout";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ChatBot from "./scenes/chat";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import ReactGA from "react-ga";
import FormBuilder from "./scenes/formBuilder";
const NotFoundPage = lazy(() => import("./scenes/NotFoundPage"));

function App() {
  const [theme, colorMode] = useMode();

  const TRACKING_ID = "G-9Z12G4P2GF"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  const { authUser } = useSelector((state) => state.authUser);

  const ProtectedRoute = ({ children }) => {
    console.log("auth:" + authUser);
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!authUser) {
      console.log("redirecting to login");
      return <Navigate to="/login" />;
    }
    console.log("display child");
    return children;
  };

  // const handleSidebarCollapsed = (collapsed) => {
  //   if (collapsed) {
  //     setMainContainerClass("mainContainerFull");
  //     console.log("collapsed is true");
  //   }
  //   else {
  //     setMainContainerClass("mainContainer");
  //     console.log("collapsed is false");
  //   }
  // };

  initializeApp(firebaseConfig);

  const Layout = () => {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <MySidebar />
            {/* <MySidebar handleSidebarCollapsed={handleSidebarCollapsed} /> */}
            <main className="mainContainer">
              {/* <Topbar setIsSidebar={setIsSidebar} /> */}
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route element={<Layout />}>
        <Route path="/chatbot" element={<ChatBot />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings/:id" element={<Settings />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/formBuilder/:schema" element={<FormBuilder />} />
        <Route path="/formBuilder" element={<FormBuilder />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="*"
        element={
          <Suspense fallback={<>...loading</>}>
            <NotFoundPage />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default App;
