import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";

const ListModal = ({ DBSchema }) => {
  return DBSchema.length ? (
    DBSchema.map(({ schema, tables }, index) => {
      const stringifySchema = JSON.stringify(tables);
      return (
        <Card key={`list-content-${schema.version}-${index}`}>
          <Box sx={{ display: "flex" }}>
            <CardContent>
              <Typography>{schema.description}</Typography>
              <TextField value={stringifySchema} disabled />
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigator.clipboard.writeText(stringifySchema)}
              >
                <ContentCopyIcon />{" "}
              </Button>
            </CardActions>
          </Box>
        </Card>
      );
    })
  ) : (
    <Typography>
      There are no versions saved for the selected set of fields
    </Typography>
  );
};

export default ListModal;
