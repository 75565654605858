import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Divider,
  Button,
  CardActions,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ApiIcon from "@mui/icons-material/Api";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Masonry } from "@mui/lab";
import "../../styles.scss";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import React from "react";
import StatBox from "../../components/StatBox";
import { deleteWorkFlow, getFlows } from "../../http/workFlow";
import ProgressCircle from "../../components/ProgressCircle";

const ChatInfoCard = ({ workFlowId, workFlow, onDelete }) => {
  return (
    <Card className="mainCard rounded-0 border" sx={{ minWidth: 250 }}>
      <CardContent>
        <Typography
          fontFamily="monospace"
          textAlign="left"
          fontWeight="bold"
          variant="h5"
        >
          {workFlow.name}
        </Typography>
        <Typography
          fontFamily="monospace"
          textAlign="left"
          fontWeight="bold"
          variant="h6"
        >
          {workFlow.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ gap: 1 }}>
        <Link
          to="/chatbot"
          // TODO: Will add workFlow id into route param
          // and remove location state
          // state={{ resourceGraph: infoCard }}
          className="btn btn-square btn-dark mt-4 rounded-0"
        >
          Test
        </Link>
        <button
          className="btn btn-square btn-dark mt-4 rounded-0 small"
          onClick={() => onDelete(workFlowId)}
        >
          Delete
        </button>
        <Link
          to={`/settings/${workFlowId}`}
          className="btn btn-square btn-light mt-4 rounded-0"
        >
          Edit
        </Link>
      </CardActions>
    </Card>
  );
};

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [workFlows, setWorkFlows] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getFlows()
      .then(({ data }) => {
        setIsLoading(false);
        if (data) {
          setWorkFlows(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("e=> ", e);
      });
  }, []);

  const onDeleteWorkFlow = (workFlowId) => {
    deleteWorkFlow(workFlowId).then(({ data: respData }) => {
      if (!!respData?.data) {
        setWorkFlows((prevWorkFlows) =>
          prevWorkFlows.filter(({ dataId }) => dataId !== workFlowId)
        );
      }
    });
  };

  return (
    <Box>
      <Box m="30px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[200],
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="430"
              subtitle="Conversations"
              progress="0.75"
              increase="+14%"
              icon={
                <QuestionAnswerIcon
                  sx={{ color: colors.primary[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="1500"
              subtitle="API Usage"
              progress="0.50"
              increase="+21%"
              icon={
                <ApiIcon
                  sx={{ color: colors.primary[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="50"
              subtitle="AI Suggestions"
              progress="0.30"
              increase="+5%"
              icon={
                <BubbleChartIcon
                  sx={{ color: colors.primary[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="1200"
              subtitle="Shared Insights"
              progress="0.80"
              increase="+43%"
              icon={
                <BarChartIcon
                  sx={{ color: colors.primary[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      <Box m="30px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header subtitle="All your saved resource graphs." />
        </Box>
        <Divider component="div" role="presentation" className="mb-4"></Divider>
        <Masonry columns={3} spacing={2}>
          {isLoading ? (
            <ProgressCircle />
          ) : (
            workFlows.map(({ dataId, ...workFlow }) => (
              <ChatInfoCard
                workFlowId={dataId}
                workFlow={workFlow}
                onDelete={onDeleteWorkFlow}
                key={`workflow-${dataId}`}
              />
            ))
          )}
        </Masonry>
      </Box>
    </Box>
  );
};

export default Dashboard;
