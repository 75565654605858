import { useCallback, useState } from "react";
import { TextField, Typography } from "@mui/material";
import ClosedIcon from "@mui/icons-material/CloseOutlined";

const Enum = ({ value = [], name, onChange, ...inputProps }) => {
  const [state, setState] = useState("");

  const handleAddBtn = useCallback(
    (e) => {
      const event = {
        ...e,
        target: { ...e.target, name, value: [...value, state] },
      };
      setState("");
      onChange(event);
    },
    [name, value, onChange, state]
  );

  const handleDeleteBtn = useCallback(
    (index) => {
      value.splice(index, 1);
      onChange({ target: { name, value: [...value] } });
    },
    [name, value, onChange]
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          type="text"
          name={name}
          value={state}
          {...inputProps}
          onChange={(e) => setState(e.target.value)}
        />

        <button
          type="button"
          onClick={handleAddBtn}
          disabled={!state}
          className="btn btn-square btn-light btn-outline-dark"
        >
          Add
        </button>
      </div>
      {value.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {value.map((val, index) => (
            <div
              key={`${index}-${val}`}
              style={{
                gap: "1rem",
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                background: "lightgray",
              }}
            >
              <Typography>{val}</Typography>
              <button
                onClick={(index) => handleDeleteBtn(index)}
                className="btn btn-square btn-light p-0"
              >
                <ClosedIcon />
              </button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Enum;
