export const resourceTree = (data) => {
  const graphResources = data.nodes.map((item, index) => ({
    id: item.id,
    type: item.type,
    name: item.data.label,
    value: item.data.value,
  }));

  var graphEdges = [];

  if (data.edges) {
    graphEdges = data.edges.map((item, index) => ({
      source: item.source,
      target: item.target,
    }));
  }

  return {
    name: data.name,
    description: data.description,
    resources: graphResources,
    connections: graphEdges,
  };
};

// export const nodeToResources = data.resources.map((item, index) => ({
//   id: item.id,
//   data: { label: item.name.slice(0, 20) },
//   type: item.type,
//   position,
// }));

// export const edgesToConnections = data.connections.map((item, index) => ({
//   id: item.connector_id,
//   source: item.connect[0],
//   target: item.connect[1],
//   type: edgeType,
//   animated: true,
// }));
