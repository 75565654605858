import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeAuthUser } from "../../storage/AuthUserSlice";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(changeAuthUser(null));
  navigate("/dashboard");
};

export default Logout;
