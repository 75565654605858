import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push({
        id: action.payload.id,
        text: action.payload.text,
        sender: action.payload.sender,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMessage } = messageSlice.actions;

export default messageSlice.reducer;
