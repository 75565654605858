import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "../../styles.scss";

const BotMessage = ({ message }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const messageContent = {
    ...(message.sender === "output"
      ? {
          borderRadius: "0px",
          backgroundColor: "#e5e9ec",
          color: colors.grey[100],
        }
      : {
          //backgroundColor: colors.primary[300],
          color: colors.grey[100],
        }),
  };

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`botMessage ${message.sender === "bot" && "bot"}`}
    >
      <div className="messageInfo">{/* <PersonIcon className="img"/> */}</div>
      <div className="messageInfo">{message.sender}</div>
      <div className="messageContent">
        <p style={messageContent}>{message.text}</p>
      </div>
    </div>
  );
};

export default BotMessage;
