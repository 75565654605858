import { useMemo } from "react";
import { MenuItem, TextField } from "@mui/material";

const selectOptions = {
  allowNull: ["true", "false"],
};

const getOptions = (name) => {
  const options =
    typeof selectOptions[name] === "function"
      ? selectOptions[name]()
      : selectOptions[name];
  return options || [];
};

const parseOptions = (options) => {
  return Array.isArray(options)
    ? options.map((option) => [option, option.toUpperCase()])
    : Object.entries(options);
};

const Select = ({
  name,
  value,
  onChange,
  options: optionsProp = getOptions(name),
  ...props
}) => {
  const options = useMemo(() => parseOptions(optionsProp), [optionsProp]);

  return (
    <TextField select name={name} value={value} onChange={onChange} {...props}>
      {options.map(([optionValue, optionLabel], index) => (
        <MenuItem key={`${index}-${optionValue}`} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
