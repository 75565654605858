import { useState } from "react";
import { Box, TextField } from "@mui/material";

const SaveSchemaModal = ({ onSave }) => {
  const [state, setState] = useState("");
  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <TextField
        variant="standard"
        label="description"
        value={state}
        onChange={(e) => setState(e.target.value)}
        required
        helperText="enter information about the schema."
      />
      <button
        onClick={() => onSave(state)}
        disabled={!state}
        className="btn btn-square rounded-0 btn-light btn-outline-dark"
      >
        save
      </button>
    </Box>
  );
};

export default SaveSchemaModal;
