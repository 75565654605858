import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import ConfirmationModalBody from "../scenes/formBuilder/FormBuilderModals/ConfirmationModalBody";
import { useModal } from "../context/Modal";

const useBlocker = (onConfirm, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }
    const push = navigator.push;
    navigator.push = (...args) => {
      const confirm = () => push(...args);
      onConfirm(confirm);
    };

    return () => {
      navigator.push = push;
    };
  }, [onConfirm, navigator, when]);
};

export const usePrompt = (message = "", when = true) => {
  const { setModal } = useModal();
  useEffect(() => {
    if (when) {
      window.onbeforeunload = (event) => {
        event.preventDefault();
        return (event.returnValue = "");
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  const onConfirm = useCallback(
    (confirmNavigation) => {
      confirmNavigation();
      setModal(null);
    },
    [setModal]
  );

  const confirmExit = useCallback(
    (confirmNavigation) => {
      setModal(
        <ConfirmationModalBody
          confirmMessage={message}
          onConfirmHandler={() => onConfirm(confirmNavigation)}
          onCancelHandler={() => setModal(null)}
        />
      );
    },
    [setModal, message, onConfirm]
  );

  useBlocker(confirmExit, when);
};
