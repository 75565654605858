import { useMemo } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";

import FieldsForm from "./FieldsForm.jsx";
import Tabs from "../../components/Tabs/Tabs.jsx";
import PatternsTabPanel from "./PatternsTabPanel.jsx";
import FieldsTabPanel from "./FieldsTabPanel.jsx";
import classes from "./FormBuilder.module.scss";

const FieldCreator = ({
  handleDragStart,
  handleDragEnd,
  constraints,
  onSubmit,
  onCancel,
}) => {
  const panels = useMemo(
    () => [
      {
        id: "patterns",
        title: "Patterns",
        content: <PatternsTabPanel {...{ handleDragStart, handleDragEnd }} />,
      },
      {
        id: "fields",
        title: "Fields",
        content: <FieldsTabPanel {...{ handleDragStart, handleDragEnd }} />,
      },
    ],
    [handleDragEnd, handleDragStart]
  );
  return (
    <Box className={`${classes.container} ${classes.dottedBorderRight}`}>
      <Grid
        container
        spacing={2}
        className="dndflow"
        style={{ height: "unset" }}
      >
        {!!constraints ? (
          <Grid item xs={12}>
            <FieldsForm
              constraints={constraints}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Tabs panels={panels} orientation="horizontal" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FieldCreator;
