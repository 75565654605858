import { createSlice } from "@reduxjs/toolkit";
// https://dev.to/dawx/react-authentication-with-firebase-3ma4

export const appuserSettingsSlice = createSlice({
  name: "appuserSettings",
  initialState: {
    appuserSettings: [],
  },
  reducers: {
    updateAppUserSettings: (state, action) => {
      state.appuserSettings = action.payload;
      console.log(state.appuserSettings);
    },
    updateSingleAppUserSettings: (state, action) => {
      var index = state.appuserSettings.findIndex(
        (i) => i.key === action.payload.key
      );
      if (index !== -1) {
        state.appuserSettings.splice(index, 1, action.payload);
      } else {
        state.appuserSettings.push(action.payload);
      }
      console.log(state.appuserSettings);
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAppUserSettings, updateSingleAppUserSettings } =
  appuserSettingsSlice.actions;

export default appuserSettingsSlice.reducer;
