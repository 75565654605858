import { createContext, useCallback, useContext, useState } from "react";
import Modal from "../../components/Modal/Modal";

const ModalContext = createContext();
const { Provider } = ModalContext;

const useModalProvider = () => {
  const [modal, setModal] = useState(null);
  const [modalProps, setModalProps] = useState({});

  return {
    modal,
    modalProps,
    setModal: useCallback((content, props = {}) => {
      setModal(content);
      setModalProps(props);
    }, []),
    closeModal: useCallback(() => {
      setModal(null);
    }, []),
  };
};

export const useModal = () => useContext(ModalContext);
export const ModalProvider = ({ children }) => {
  const value = useModalProvider();
  return (
    <Provider value={value}>
      <Modal open={!!value.modal} handleClose={() => {}}>
        {value.modal}
      </Modal>
      {children}
    </Provider>
  );
};
