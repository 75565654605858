import { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";

import {
  applyFormExtensions,
  getRequiredFieldValidatorsMap,
  parseTable,
  requiredFieldValidations,
  validateFields,
} from "./utils";
import FieldGroup from "../../components/FieldGroup";
import { optionsByType } from "./constants";

const defaultInputFields = {
  name: {
    type: "string",
  },
  allowNull: {
    label: "Required",
    type: "select",
  },
  info: {
    type: "string",
  },
  pattern: {
    type: "string",
  },
};

const requiredFields = ["name", "info", "allowNull"];

const requiredFieldsValidatorsMap =
  getRequiredFieldValidatorsMap(requiredFields);

const inputFieldsByType = {
  enum: {
    options: {
      type: "enum",
    },
  },
  // Will Add More Type Specific Fields Here
};

const getInputFieldsMapByInputType = (type) => ({
  ...defaultInputFields,
  ...inputFieldsByType[type],
});

const FieldsForm = ({ constraints, onSubmit, onCancel }) => {
  const formFieldsByType = useMemo(
    () => getInputFieldsMapByInputType(constraints.type.toLowerCase()),
    [constraints.type]
  );
  const [state, setState] = useState(() =>
    parseTable(formFieldsByType, constraints)
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setState(parseTable(formFieldsByType, constraints));
  }, [constraints, formFieldsByType]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  }, []);

  const handleSubmitBtn = useCallback(() => {
    const errors = validateFields(state, [
      ...requiredFieldsValidatorsMap,
      ...(constraints.type === "ENUM"
        ? [["options", requiredFieldValidations]]
        : []),
    ]);
    let hasError = Object.keys(errors).length;
    if (!!hasError) {
      return setErrors(errors);
    }
    onSubmit({ ...constraints, ...state });
  }, [onSubmit, constraints, state]);

  const fieldExtensions = useMemo(() => {
    return {
      name: (props) => ({ ...props, disabled: !!constraints.name }),
      pattern: (props) => {
        props.type = "string";
        if (["date", "string"].includes(constraints.type)) {
          props.type = "select";
          props.options = [...optionsByType[constraints.type]];

          if (constraints.pattern) props.options.push(constraints.pattern);
        }
        return {
          ...props,
        };
      },
    };
  }, [constraints]);

  const formFields = useMemo(
    () => applyFormExtensions(formFieldsByType, fieldExtensions),
    [fieldExtensions, formFieldsByType]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Typography variant="h4">{constraints.type}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldGroup
          fields={formFields}
          state={state}
          onChange={handleChange}
          requiredFields={requiredFields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldsFormFooter onSubmit={handleSubmitBtn} onCancel={onCancel} />
      </Grid>
    </Grid>
  );
};

export default FieldsForm;

const FieldsFormFooter = ({ onSubmit, onReset, onCancel }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "1rem",
      }}
    >
      {onSubmit && (
        <button
          className="btn btn-square btn-light btn-outline-dark rounded-0"
          onClick={onSubmit}
        >
          Submit
        </button>
      )}
      {onReset && (
        <button
          className="btn btn-square btn-light btn-outline-dark rounded-0"
          onClick={onReset}
        >
          Reset
        </button>
      )}
      {onCancel && (
        <button
          className="btn btn-square btn-dark rounded-0"
          onClick={onCancel}
        >
          Cancel
        </button>
      )}
    </div>
  );
};
