import { Box, CircularProgress } from "@mui/material";

const ProgressCircle = ({ progress = "0.75", size = "40" }) => {
  return (
    <Box>
      <CircularProgress color="neutral" />
    </Box>
  );
};

export default ProgressCircle;
