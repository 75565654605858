import { createSlice } from "@reduxjs/toolkit";

export const infoCardSlice = createSlice({
  name: "infoCard",
  initialState: {
    infoCards: [],
  },
  reducers: {
    addInfoCard: (state, action) => {
      state.infoCards.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addInfoCard } = infoCardSlice.actions;

export default infoCardSlice.reducer;
