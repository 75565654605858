import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  ConnectionLineType,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Grid, Button, Card, CardContent } from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dagre from "dagre";

import "../../styles.scss";
import ContextPromptTabPanel from "./ContextPromptTabPanel";
import DefaultPromptTabPanel from "./DefaultPromptTabPanel";
import Field from "../../components/Field";
import { getFlow, saveFlow, updateFlow } from "../../http/workFlow";
import { usePrompt } from "../../hooks/useCustomPrompt";

const promptTabPanelRendererMap = {
  context: (props) => <ContextPromptTabPanel {...props} />,
  default: (props) => <DefaultPromptTabPanel {...props} />,
};

const YAML = require("json-to-pretty-yaml");

function generateTransactionID() {
  return Math.random().toString(36).substring(2);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Start DAGRE Flow
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    // commenting out below code because, after saving workflow,
    // workflow fetches again based on id, so workFlow nodes go out of display
    // const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    // node.position = {
    //   x: nodeWithPosition.x - nodeWidth / 2,
    //   y: nodeWithPosition.y - nodeHeight / 2,
    // };

    return node;
  });

  return { nodes, edges };
};
// End DAGRE Flow

// let id = 0;
const getId = () => `${generateTransactionID()}`;
const initialWorkFlowState = {
  name: "",
  description: "",
};

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [inputState, setInputState] = useState(initialWorkFlowState);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const selectedItem = nodes.find((item) => item.selected) || { type: "" };
  const changeRef = useRef(JSON.stringify({ inputState, nodes, edges }));

  const hasWorkFlowEdited =
    changeRef.current !== JSON.stringify({ inputState, nodes, edges });
  usePrompt("Changes you made may not be saved.", hasWorkFlowEdited);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: ConnectionLineType.SmoothStep,
            animated: true,
          },
          eds
        )
      ),
    [setEdges]
  );

  useEffect(() => {
    if (id) {
      getFlow(id)
        .then(({ data: respData }) => {
          if (respData) {
            const { data } = respData;
            const parsedData = JSON.parse(data);
            const { name, description, nodes, edges } = parsedData;
            const { nodes: layoutedNodes, edges: layoutedEdges } =
              getLayoutedElements(nodes, edges);
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
            reactFlowInstance.fitView();
            const inputState = { name, description };
            setInputState(inputState);
            changeRef.current = JSON.stringify({
              inputState,
              nodes: layoutedNodes,
              edges: layoutedEdges,
            });
          }
        })
        .catch((e) => {
          console.log("error=> ", e);
        });
    }
  }, [reactFlowInstance, id, setEdges, setNodes]);

  const SaveInDb = () => {
    setIsLoading(true);
    const data = {
      Name: inputState.name,
      description: inputState.description,
      data: JSON.stringify({
        name: inputState.name,
        description: inputState.description,
        nodes: nodes,
        edges: edges,
      }),
    };
    if (!id) {
      saveFlow(data)
        .then(({ data: respData }) => {
          const { data } = respData;
          setIsLoading(false);
          navigate(`/settings/${data}`, { replace: true });
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      updateFlow(id, data).then(({ data: respData }) => {
        if (!!respData) {
          changeRef.current = JSON.stringify({ inputState, nodes, edges });
        }
        setIsLoading(false);
      });
    }
  };

  const handleAddSchema = (schema) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.selected === true) {
          node.data = {
            ...node.data,
            value: schema,
          };
        }
        return node;
      })
    );
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow/type");
      const label = event.dataTransfer.getData("application/reactflow/label");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${label}` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );

  const onDragStart = (event, nodeType, nodeLabel) => {
    event.dataTransfer.setData("application/reactflow/type", nodeType);
    event.dataTransfer.setData("application/reactflow/label", nodeLabel);
    event.dataTransfer.effectAllowed = "move";
  };

  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const onInputChange = useCallback(
    ({ target: { name, value } }) =>
      setInputState((prevState) => ({ ...prevState, [name]: value })),
    []
  );

  const onNodeClick = (_, node) => {
    setNodes((nodes) =>
      nodes.map((nde) => {
        if (node.id === nde.id) {
          nde.style = { ...nde.style, backgroundColor: "#FFB4B4" };
        } else {
          nde.style = { ...nde.style, backgroundColor: "#ffffff" };
        }
        return nde;
      })
    );
  };

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView
            onNodeClick={onNodeClick}
          >
            <Controls />
          </ReactFlow>
        </div>
        <Grid sx={{ position: "absolute", padding: 2 }}>
          <Grid item xs={1} />
          <Grid item xs={10} style={{ display: "flex", gap: "1rem" }}>
            <Button
              className="btn btn-square btn-light border border-dark rounded-0"
              onClick={SaveInDb}
            >
              Save
            </Button>
            <Link
              to="/chatbot"
              state={{
                reactFlowGraph: {
                  name: inputState.name,
                  description: inputState.description,
                  nodes: nodes,
                  edges: edges,
                },
              }}
              className="btn btn-square btn-dark border border-dark rounded-0"
            >
              Test
            </Link>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <aside style={{ height: "100vh", overflow: "auto" }}>
          <Box className="chatSideBar" display="flex" flexDirection="column">
            <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
              <Field
                name="name"
                type="string"
                value={inputState.name}
                onChange={onInputChange}
                fullWidth
              />
              <Field
                name="description"
                type="string"
                value={inputState.description}
                onChange={onInputChange}
                fullWidth
              />
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                aria-label="resourc graph diagram"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Resources" {...a11yProps(0)} />
                <Tab label="Add Prompt" {...a11yProps(1)} />
                <Tab label="Details" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="description">
                Choose your input and output medium.
              </div>
              <div
                className="dndnode input"
                onDragStart={(event) => onDragStart(event, "input", "input")}
                draggable
              >
                Text Input
              </div>
              <div
                className="dndnode output"
                onDragStart={(event) => onDragStart(event, "output", "output")}
                draggable
              >
                Text Output
              </div>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "context", "data_schema")
                }
                draggable
              >
                Schema
              </div>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "database", "snowflake")
                }
                draggable
              >
                Snowflake DB
              </div>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "database", "local_db")
                }
                draggable
              >
                Local DB
              </div>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "model", "language_model")
                }
                draggable
              >
                Language Model
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {promptTabPanelRendererMap[selectedItem.type]
                ? promptTabPanelRendererMap[selectedItem.type]({
                    data: selectedItem?.data?.value,
                    onAddSchema: handleAddSchema,
                  })
                : promptTabPanelRendererMap.default({
                    data: selectedItem?.data?.value,
                    onAddSchema: handleAddSchema,
                  })}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="description">Meta Data</div>
              {/* {nodes.map((node) => (
                <pre>
                  {YAML.stringify({
                    node: node.data.label,
                    prompt: node.data.value,
                    id: node.id,
                  })}
                </pre>
              ))} */}
              {nodes.map((node) => (
                <div className="infoCardContent">
                  <Card className="mainCard">
                    <CardContent
                      style={{
                        border: "1px solid",
                        backgroundColor: "#e5e9ec",
                      }}
                    >
                      <pre>
                        <code className="js">
                          {YAML.stringify({
                            id: node.id,
                            node: node.data.label,
                            prompt: node.data.value,
                          })}
                        </code>
                      </pre>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </TabPanel>
          </Box>
        </aside>
      </ReactFlowProvider>
    </div>
  );
};

export default DnDFlow;
