import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// https://dev.to/dawx/react-authentication-with-firebase-3ma4

const saveAuthUserToCookies = (authUser) => {
  Cookies.set("authUser", JSON.stringify(authUser), { expires: 7 }); // Set the cookie with a 7-day expiration
};

const clearAuthUserToCookies = () => {
  Cookies.remove("authUser");
};

const loadAuthUserFromCookies = () => {
  const authUser = Cookies.get("authUser");
  return authUser ? JSON.parse(authUser) : null;
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState: {
    authUser: loadAuthUserFromCookies(),
  },
  reducers: {
    changeAuthUser: (state, action) => {
      state.authUser = action.payload;
      saveAuthUserToCookies(state.authUser);
    },
    clearAuthUser: (state) => {
      state.authUser = null;
      clearAuthUserToCookies();
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeAuthUser, clearAuthUser } = authUserSlice.actions;

export default authUserSlice.reducer;
