import apiService from "../services/apiService";

const route = "/api/v1.0/LargeKeyValue";

export const getFlows = () => apiService.get(route);
export const getFlow = (id) => apiService.get(`${route}/${id}`);

export const saveFlow = (data = {}) => apiService.post(route, data);

export const updateFlow = (id = "", data = {}) =>
  apiService.post(`${route}/${id}`, data);

export const deleteWorkFlow = (id) => apiService.delete(`${route}/${id}`);
