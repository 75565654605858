import { Box, Card, Typography } from "@mui/material";
import classNames from "classnames";

import classes from "./FormBuilder.module.scss";
import FieldViewerCardList from "./FieldViewerCardList";
import { useEffect, useRef } from "react";

const FieldViewer = ({
  fields,
  tableErrors = {},
  droppedEle,
  draggingEle,
  handleEleDrop,
  onEdit,
  onDelete,
}) => {
  const { options = {}, description = "" } = fields;
  const ref = useRef();

  useEffect(() => {
    if (!!droppedEle && !droppedEle.name) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [droppedEle]);

  const isFieldDragging =
    !!draggingEle && draggingEle.componentType === "field";

  return (
    <Box
      className={classNames(classes.container, {
        "bg-secondary": isFieldDragging,
      })}
      onDragOverCapture={(e) => e.preventDefault()}
      onDrop={handleEleDrop}
    >
      <h4>{description}</h4>
      <FieldViewerCardList
        fields={options}
        draggingEle={draggingEle}
        tableErrors={tableErrors}
        onEdit={onEdit}
        onDelete={onDelete}
        handleEleDrop={handleEleDrop}
      />
      {!!droppedEle && !droppedEle.name ? (
        <Card
          className="d-md-flex border rounded-0 border-warning p-2 mt-2"
          sx={{ overflow: "unset" }}
        >
          <Box>
            <Typography>dropped: {droppedEle.type}</Typography>
          </Box>
        </Card>
      ) : null}
      <div ref={ref}></div>
    </Box>
  );
};

export default FieldViewer;
