import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useDeferredValue,
} from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import FieldCreator from "./FieldCreator";
import FieldViewer from "./FieldViewer";
import * as dbs from "../../data/mockSchema";
import TableNav from "./TableNav";
import ListModal from "./FormBuilderModals/ListModal";
import SaveSchemaModal from "./FormBuilderModals/SaveSchemaModal";
import classes from "./FormBuilder.module.scss";
import Modal from "../../components/Modal/Modal";
import ConfirmationModalBody from "./FormBuilderModals/ConfirmationModalBody";
import { getContexts, postContext } from "../../http/contextData";

const defaultSchemaName = "snowflake_db_versions";

function FormBuilder() {
  const userId = useSelector((state) => state.authUser.authUser.id);
  const { schema = defaultSchemaName } = useParams();
  const [modalBody, setModalBody] = useState(null);

  const [isUserSchemasLoading, setIsUserSchemasLoading] = useState(false);
  const [userSchemas, setUserSchemas] = useState([]);
  const [tables, setTables] = useState(() => {});
  const [selectedTable, setSelectedTable] = useState("");
  const deferredSelectedTable = useDeferredValue(selectedTable);
  const [tablesError, setTablesError] = useState({});
  const [dragElementType, setDragElementType] = useState(null);
  const [droppedEle, setDroppedEle] = useState(null);

  useEffect(() => {
    if (!!userId) {
      setIsUserSchemasLoading(true);
      getContexts(userId).then(({ data, error }) => {
        if (!!data) {
          const { schemas = [] } = data.find(({ id }) => id === schema) || {};
          setUserSchemas(schemas);
        }
        if (!!error) {
          // TODO: show notification
          console.log("error=> ", error);
        }
        setIsUserSchemasLoading(false);
      });
    }
  }, [userId, schema]);

  const { dbTableNames, mockDbSchema } = useMemo(() => {
    const selectedDb = dbs[schema];
    const obj = {
      mockDbSchema: selectedDb,
      dbTableNames: Object.keys(selectedDb),
    };
    return obj;
  }, [schema]);

  useEffect(() => {
    setSelectedTable(dbTableNames[0]);
    setTables(mockDbSchema);
  }, [mockDbSchema, dbTableNames]);

  const handleTableSelect = useCallback((tableName) => {
    setSelectedTable(tableName);
    setDroppedEle(null);
  }, []);

  const handleDragStart = useCallback((e, eleType) => {
    setDragElementType(eleType);
  }, []);

  const handleDragEnd = useCallback(() => {
    setDragElementType(null);
  }, []);

  const editTable = useCallback(
    (fieldName, fieldValues) => {
      setTables((prevTables) => ({
        ...prevTables,
        [selectedTable]: {
          ...prevTables[selectedTable],
          options: {
            ...prevTables[selectedTable].options,
            [fieldName]: {
              ...prevTables[selectedTable].options[fieldName],
              ...fieldValues,
            },
          },
        },
      }));
    },
    [selectedTable]
  );

  // it runs on both, when new field dropped
  // and also when existing field edit
  const onEdit = useCallback((field) => {
    setDroppedEle(field);
  }, []);

  const handleDrop = useCallback(
    (e, type, fieldName) => {
      // stop propagation used as this function is being
      // called from fieldViewer when new field is dropped
      // also called from fieldCardList when pattern or any other
      // prop dropped inside card
      e.stopPropagation();
      e.preventDefault();
      setDragElementType(null);
      if (dragElementType.componentType === "field") {
        onEdit({ type: dragElementType.type });
        return;
      }
      if (type === dragElementType?.type) {
        editTable(fieldName, { pattern: dragElementType?.pattern });
      }
    },
    [dragElementType, editTable, onEdit]
  );

  const onCancelForm = useCallback(() => {
    setDroppedEle(null);
  }, []);

  const onSubmitForm = useCallback(
    (fieldValues) => {
      const { name: colName, ...restInfo } = fieldValues;
      editTable(colName, { ...restInfo, isContextRequired: false });
      setDroppedEle(null);
      setTablesError((prevErrors) => {
        if (!!prevErrors?.[selectedTable]?.[colName])
          delete prevErrors[selectedTable][colName];
        return { ...prevErrors };
      });
    },
    [editTable, selectedTable]
  );

  const onConfirmDelete = useCallback(
    (fieldName) => {
      setTables((prevTables) => {
        delete prevTables[selectedTable].options[fieldName];
        return {
          ...prevTables,
          [selectedTable]: {
            ...prevTables[selectedTable],
            options: {
              ...prevTables[selectedTable].options,
            },
          },
        };
      });
      setModalBody(null);
    },
    [selectedTable]
  );

  const onDelete = useCallback(
    (fieldName) => {
      setModalBody(
        <ConfirmationModalBody
          confirmBtnText="delete"
          onConfirmHandler={() => onConfirmDelete(fieldName)}
        />
      );
    },
    [onConfirmDelete]
  );

  const onSaveSchema = useCallback(
    async (description) => {
      const newSchema = {
        schema: {
          version: userSchemas.length + 1,
          description,
        },
        tables: {
          ...tables,
        },
      };
      const updatedUserSchemas = [...userSchemas, newSchema];
      await postContext({
        user_id: userId.toString(),
        id: schema,
        schemas: updatedUserSchemas,
      });
      setUserSchemas(updatedUserSchemas);
      setModalBody(null);
    },
    [tables, userId, userSchemas, schema]
  );

  const handleSaveBtn = useCallback(() => {
    const errorMsg = "Description is required for this column";
    // TODO: convert below calculation into a utility
    const tablesErrorMap = Object.entries(tables).reduce(
      (acc, [tableName, { options }]) => {
        const tableErrorMap = Object.entries(options).reduce(
          (a, [fieldName, { isContextRequired = false }]) => {
            if (isContextRequired) {
              a[fieldName] = errorMsg;
            }
            return a;
          },
          {}
        );

        if (Object.keys(tableErrorMap).length) {
          acc[tableName] = tableErrorMap;
        }
        return acc;
      },
      {}
    );

    if (!Object.keys(tablesErrorMap).length) {
      setModalBody(<SaveSchemaModal onSave={onSaveSchema} />);
      return;
    }
    setTablesError(tablesErrorMap);
  }, [onSaveSchema, tables]);

  const handleListSchemaBtn = useCallback(() => {
    setModalBody(<ListModal DBSchema={userSchemas} />);
  }, [userSchemas]);

  if (isUserSchemasLoading) {
    return <>...loading</>;
  }
  return (
    <Box sx={{ flexGrow: 1, background: "#ffff" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableNav
            selectedTable={selectedTable}
            tablesError={tablesError}
            handleTableSelect={handleTableSelect}
            dbTableNames={dbTableNames}
            handleSaveBtn={handleSaveBtn}
            handleListSchemaBtn={handleListSchemaBtn}
          />
        </Grid>
        <Grid item xs={6} classes={{ root: classes.paddingTopZero }}>
          <FieldCreator
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            constraints={droppedEle}
            onCancel={onCancelForm}
            onSubmit={onSubmitForm}
          />
        </Grid>
        <Grid item xs={6} classes={{ root: classes.paddingTopZero }}>
          <FieldViewer
            fields={tables?.[deferredSelectedTable] || {}}
            tableErrors={tablesError?.[deferredSelectedTable] || {}}
            droppedEle={droppedEle}
            draggingEle={dragElementType}
            handleEleDrop={handleDrop}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      <Modal open={!!modalBody} handleClose={() => setModalBody(null)}>
        {modalBody}
      </Modal>
    </Box>
  );
}

export default FormBuilder;
