import { useCallback, useMemo, useState } from "react";
import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";

const a11yProps = (index, slug) => {
  return {
    id: `tab-${index}-${slug}`,
    "aria-controls": `tabpanel-${index}-${slug}`,
  };
};
const generateTabComponents = (panels, value) =>
  panels.reduce(
    (acc, { title, id, content, tabProps = {}, panelProps = {} }, index) => {
      const slug = id || title;
      const a11yPropValues = a11yProps(index, slug);
      const keyBase = `${slug}-${index}`;

      acc.tabComponents.push(
        <Tab
          key={`Tab-${keyBase}`}
          label={title}
          {...tabProps}
          {...a11yPropValues}
        />
      );

      acc.panelComponents.push(
        <TabPanel
          key={`TabPanel-${keyBase}`}
          value={value}
          index={index}
          id={a11yPropValues.id}
          labelledBy={a11yPropValues["aria-controls"]}
          {...panelProps}
        >
          {content}
        </TabPanel>
      );
      return acc;
    },
    {
      tabComponents: [],
      panelComponents: [],
    }
  );
const Tabs = ({ panels: panelsProp, orientation = "vertical" }) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback((_event, newValue) => {
    setValue(newValue);
  }, []);

  const { tabComponents, panelComponents } = useMemo(
    () => generateTabComponents(panelsProp, value),
    [panelsProp, value]
  );
  const isVertical = orientation === "vertical";
  const layoutType = isVertical ? "row" : "column";
  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: layoutType }}>
      <MuiTabs
        orientation={orientation}
        variant="scrollable"
        value={value}
        onChange={handleChange}
      >
        {tabComponents}
      </MuiTabs>
      {panelComponents}
    </Box>
  );
};

export default Tabs;
