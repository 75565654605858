import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/BookmarkAddOutlined";
import ListIcon from "@mui/icons-material/List";
import SourceIcon from "@mui/icons-material/SourceOutlined";
import { Menu, MenuItem, Button, Box, AppBar, Toolbar } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import classes from "./FormBuilder.module.scss";
import * as dbs from "../../data/mockSchema";

const schemaMap = Object.keys(dbs).reduce((acc, key) => {
  const value = key.slice(0, key.indexOf("_"));
  acc[key] = value;
  return acc;
}, {});

const navBarScrollOffset = 50;

const TableNav = ({
  selectedTable,
  tablesError,
  dbTableNames = [],
  handleTableSelect,
  handleSaveBtn = () => {},
  handleListSchemaBtn = () => {},
}) => {
  const navBarRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleScrollRight = () => {
    navBarRef.current.scrollLeft += navBarScrollOffset;
  };
  const handleScrollLeft = () => {
    navBarRef.current.scrollLeft -= navBarScrollOffset;
  };

  const handleOpen = useCallback((e) => setAnchorEl(e.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const open = Boolean(anchorEl);

  return (
    <Box sx={{ flexGrow: 1, maxHeight: "5vh" }}>
      <AppBar
        position="static"
        classes={{ root: `${classes.heightUnset} bg-white p-0` }}
      >
        <Toolbar
          classes={{ root: `${classes.heightUnset} p-0 w-100` }}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flex: 3, overflowX: "hidden" }}>
            <Button onClick={handleScrollLeft}>
              <ArrowLeftIcon />
            </Button>
            <div
              ref={navBarRef}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                overflow: "hidden",
              }}
            >
              {dbTableNames.map((tableName, index) => {
                const borderColor =
                  tableName === selectedTable ? "border-dark" : "";
                const hasError =
                  tablesError?.[tableName] &&
                  Object.values(tablesError[tableName]).length;
                return (
                  <button
                    key={`${index}-${tableName}`}
                    onClick={() => handleTableSelect(tableName)}
                    className={`btn text-nowrap rounded-0 ${borderColor} ${
                      hasError ? "border-danger" : ""
                    }`}
                  >
                    {tableName}
                  </button>
                );
              })}
            </div>
            <Button onClick={handleScrollRight}>
              <ArrowRightIcon />
            </Button>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <Button onClick={handleSaveBtn}>
              <BookmarkIcon />
            </Button>
            <Button onClick={handleListSchemaBtn}>
              <ListIcon />
            </Button>
            <Button
              id="source-button"
              aria-controls={open ? "source-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOpen}
              classes={{ root: "btn btn-light" }}
            >
              <SourceIcon />
            </Button>
            <SourceMenu anchorEl={anchorEl} handleClose={handleClose} />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default TableNav;

const SourceMenu = ({ anchorEl, handleClose }) => {
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = useCallback(
    (schema) => {
      navigate(`/formBuilder/${schema}`, { replace: true });
      handleClose();
    },
    [navigate, handleClose]
  );
  return (
    <Menu
      id="source-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{ "aria-labelledby": "source-button" }}
    >
      {Object.entries(schemaMap).map(([key, value]) => (
        <MenuItem key={`menu-item-${key}`} onClick={() => handleClick(key)}>
          {value} schema
        </MenuItem>
      ))}
    </Menu>
  );
};
