export const patient_tracking = {
  "Patient Tracking": {
    type: "object",
    options: {
      cTO_timein: {
        type: "date",
        pattern: "HH:MM",
        description: "What time did you arrive at the visit?",
      },
      cTO_timeout: {
        type: "date",
        pattern: "HH:MM",
        description: "What time did you leave the visit?",
      },
      associatedMileage: {
        type: "number",
        description: "How much mileage was associated with your visit?",
      },
      selectedHCPCSCode: {
        type: "ENUM",
        description: "What was the primary reason for this visit?",
        options: [
          "(G0162) Management and evaluation of the plan of care",
          "(G0299) Direct skilled nursing services of an RN",
          "(G0300) Direct skilled nursing services of an LPN",
          "(G0493) (RN) - Observation and assessment of the patient's condition",
          "(G0494) (LPN) - Observation and assessment of the patient's condition",
          "(G0495) Skilled services of a (RN), in the training and/or education of a patient or family member",
          "(G0496) Skilled services of a (LPN), in the training and/or education of a patient or family member",
        ],
      },
      selectedQCode: {
        type: "ENUM",
        description: "Where were the home health services provided?",
        options: [
          "(Q5001) Care provided in patient s home/residence",
          "(Q5002) Care provided in assisted living facility",
          "(Q5009) Care provided in place not otherwise specified (NO)",
        ],
      },
      M0020_PAT_ID: {
        type: "string",
        description: "What is the patient's ID number?",
      },
      M0030_START_CARE_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description: "What is the start of care date for the patient?",
      },
      M0032_ROC_DT_NA: {
        type: "boolean",
        description:
          "Is the resumption of care date not applicable for the patient?",
      },
      M0032_ROC_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description: "What is the resumption of care date for the patient?",
      },
      cESD_date: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description: "What is the start date of the episode?",
      },
      M0040_PAT_LNAME: {
        type: "string",
        description: "What is the patient's last name?",
      },
      M0040_PAT_SUFFIX: {
        type: "string",
        description: "What is the patient's suffix (if any)?",
      },
      M0040_PAT_FNAME: {
        type: "string",
        description: "What is the patient's first name?",
      },
      M0040_PAT_MI: {
        type: "string",
        description: "What is the patient's middle initial?",
      },
      M0064_SSN: {
        type: "string",
        pattern: "XXX-XX-XXXX",
        description: "What is the patient's Social Security Number?",
      },
      M0064_SSN_UK: {
        type: "boolean",
        description:
          "Is the patient's Social Security Number unknown or not available?",
      },
      cPA_patientaddress: {
        type: "string",
        description: "What is the patient's street address?",
      },
      cPA_patientcity: {
        type: "string",
        description: "What city does the patient live in?",
      },
      cPA_patientphone: {
        type: "string",
        description: "What is the patient's phone number?",
      },
      M0050_PAT_ST: {
        type: "string",
        description: "What is the patient's state of residence?",
      },
      M0060_PAT_ZIP: {
        type: "string",
        description: "What is the patient's ZIP code?",
      },
      M0063_MEDICARE_NUM: {
        type: "number",
        description: "What is the patient's Medicare number, including suffix?",
      },
      M0063_MEDICARE_NA: {
        type: "boolean",
        description: "Does the patient not have Medicare?",
      },
      M0065_MEDICAID_NUM: {
        type: "number",
        description: "What is the patient's Medicaid number?",
      },
      M0065_MEDICAID_NA: {
        type: "boolean",
        description: "Does the patient not have Medicaid?",
      },
      M0066_PAT_BIRTH_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description: "What is the patient's birth date?",
      },
      A1005A: {
        type: "boolean",
        description:
          "Is the patient not of Hispanic, Latino/a, or Spanish origin?",
      },
      A1005B: {
        type: "boolean",
        description:
          "Is the patient of Mexican, Mexican American, or Chicano/a origin?",
      },
      A1005C: {
        type: "boolean",
        description: "Is the patient of Puerto Rican origin?",
      },
      A1005D: {
        type: "boolean",
        description: "Is the patient of Cuban origin?",
      },
      A1005E: {
        type: "boolean",
        description:
          "Is the patient of another Hispanic, Latino, or Spanish origin?",
      },
      A1005X: {
        type: "boolean",
        description:
          "Was the patient unable to respond to the question about their ethnicity?",
      },
      A1005Y: {
        type: "boolean",
        description:
          "Did the patient decline to respond to the question about their ethnicity?",
      },
      A1010A: {
        type: "boolean",
        description: "Is the patient's race White?",
      },
      A1010B: {
        type: "boolean",
        description: "Is the patient's race Black or African American?",
      },
      A1010C: {
        type: "boolean",
        description: "Is the patient's race American Indian or Alaska Native?",
      },
      A1010D: {
        type: "boolean",
        description: "Is the patient's race Asian Indian?",
      },
      A1010E: {
        type: "boolean",
        description: "Is the patient's race Chinese?",
      },
      A1010F: {
        type: "boolean",
        description: "Is the patient's race Filipino?",
      },
      A1010G: {
        type: "boolean",
        description: "Is the patient's race Japanese?",
      },
      A1010H: {
        type: "boolean",
        description: "Is the patient's race Korean?",
      },
      A1010I: {
        type: "boolean",
        description: "Is the patient's race Vietnamese?",
      },
      A1010J: {
        type: "boolean",
        description: "Is the patient's race Other Asian?",
      },
      A1010K: {
        type: "boolean",
        description: "Is the patient's race Native Hawaiian?",
      },
      A1010L: {
        type: "boolean",
        description: "Is the patient's race Guamanian or Chamorro?",
      },
      A1010M: {
        type: "boolean",
        description: "Is the patient's race Samoan?",
      },
      A1010N: {
        type: "boolean",
        description: "Is the patient's race Other Pacific Islander?",
      },
      A1010X: {
        type: "boolean",
        description: "Was the patient unable to respond to the race question?",
      },
      A1010Y: {
        type: "boolean",
        description: "Did the patient decline to respond to the race question?",
      },
      A1010Z: {
        type: "boolean",
        description:
          "Does the patient's race not fall into any of the above categories?",
      },
      A1110A: {
        type: "string",
        description: "What is the patient's preferred language?",
      },
      A1110A_NA: {
        type: "boolean",
        description:
          "Is the patient's preferred language not assessed or is there no information about it?",
      },
      fListLanguageKey: {
        type: "ENUM",
        description:
          "What is the patient's primary language?  Make sure to output 'Other/Unknown' if it's not one of the languages listed.",
        options: [
          "English",
          "Spanish",
          "Chinese",
          "Russian",
          "Vietnamese",
          "Other/Unknown",
        ],
      },
      cCu_ispractices_cY: {
        type: "boolean",
        description:
          "Does the patient have cultural practices that influence health care?",
      },
      cCu_ispractices_cN: {
        type: "boolean",
        description:
          "Does the patient NOT have cultural practices that influence health care?",
      },
      cCu_details: {
        type: "string",
        description:
          "If the patient has cultural practices that influence health care, please explain.",
      },
      cCu_isreligion_cY: {
        type: "boolean",
        description: "Is religion important to the patient?",
      },
      cCu_isreligion_cN: {
        type: "boolean",
        description: "Is religion NOT important to the patient?",
      },
      cCu_religionpref: {
        type: "string",
        description: "What is the patient's religious preference?",
      },
      cCu_family: {
        type: "boolean",
        description:
          "Does the patient prefer to use a family member as an interpreter?",
      },
      cCu_friend: {
        type: "boolean",
        description:
          "Does the patient prefer to use a friend as an interpreter?",
      },
      cCu_professional: {
        type: "boolean",
        description:
          "Does the patient prefer to use a professional as an interpreter?",
      },
      cCu_other: {
        type: "boolean",
        description:
          "Does the patient prefer to use another source as an interpreter?",
      },
      cCu_otherdetails: {
        type: "string",
        description:
          "If the patient prefers to use another source as an interpreter, please specify.",
      },
      cCu_emosupport: {
        type: "string",
        description:
          "Who is the patient's primary source of emotional support?",
      },
      A1110B_1: {
        type: "boolean",
        description:
          "Does the patient need or want an interpreter to communicate with a doctor or health care staff?",
      },
      A1110B_2: {
        type: "boolean",
        description:
          "Does the patient need or want an interpreter to communicate with a doctor or health care staff?",
      },
      A1110B_3: {
        type: "boolean",
        description:
          "Does the patient need or want an interpreter to communicate with a doctor or health care staff?",
      },
      M0069m: {
        type: "boolean",
        description: "Is the patient male?",
      },
      M0069f: {
        type: "boolean",
        description: "Is the patient female?",
      },
      displayText_cPhy_physicianKey: {
        type: "string",
        description: "Who is the physician for the patient?",
      },
      cEC_SecondPhysician: {
        type: "string",
        description:
          "What is the name of the secondary physician for the patient?",
      },
      cEC_SecondPhysicianPhoneA: {
        type: "string",
        description:
          "What is the area code of the secondary physician's phone number?",
      },
      cEC_SecondPhysicianPhoneB: {
        type: "number",
        description:
          "What are the first three digits of the secondary physician's phone number after the area code?",
      },
      cEC_SecondPhysicianPhoneC: {
        type: "number",
        description:
          "What are the last four digits of the secondary physician's phone number?",
      },
      cEC_ContactName: {
        type: "string",
        description:
          "What is the name of the emergency contact for the patient?",
      },
      cEC_ContactRelationship: {
        type: "string",
        description:
          "What is the relationship of the emergency contact to the patient?",
      },
      cEC_ContactAddress: {
        type: "string",
        description: "What is the address of the emergency contact?",
      },
      cEC_ContactCity: {
        type: "string",
        description:
          "What city does the emergency contact live in?  Even if the full address is not mentioned, please enter the city name if mentioned.",
      },
      cEC_ContactState: {
        type: "string",
        description:
          "What state does the emergency contact live in?  Even if the full address is not mentioned, please enter the state abbreviation if mentioned.",
      },
      cEC_ContactZipCode: {
        type: "number",
        description:
          "What is the zip code of the emergency contact's address?  Even if the full address is not mentioned, please enter the zip code if mentioned.",
      },
      cEC_ContactZip4: {
        type: "number",
        description:
          "What is the 4-digit extension of the emergency contact's zip code?",
      },
      cEC_EmergencyPhoneA: {
        type: "string",
        description:
          "What is the area code of the emergency contact's phone number?",
      },
      cEC_EmergencyPhoneB: {
        type: "number",
        description:
          "What are the first three digits of the emergency contact's phone number after the area code?",
      },
      cEC_EmergencyPhoneC: {
        type: "number",
        description:
          "What are the last four digits of the emergency contact's phone number?",
      },
      M0150_CPAY_NONE: {
        type: "boolean",
        description: "Is there no charge for the current services?",
      },
      M0150_CPAY_MCARE_FFS: {
        type: "boolean",
        description:
          "Is the payment source for home care traditional fee-for-service Medicare?",
      },
      M0150_CPAY_MCARE_HMO: {
        type: "boolean",
        description:
          "Is the payment source for home care a Medicare HMO/Managed Care/Advantage plan?",
      },
      M0150_CPAY_MCAID_FFS: {
        type: "boolean",
        description:
          "Is the payment source for home care traditional fee-for-service Medicaid?",
      },
      M0150_CPAY_MCAID_HMO: {
        type: "boolean",
        description:
          "Is the payment source for home care a Medicaid HMO/Managed Care plan?",
      },
      M0150_CPAY_WRKCOMP: {
        type: "boolean",
        description:
          "Is the payment source for home care Worker's compensation?",
      },
      M0150_CPAY_TITLEPGMS: {
        type: "boolean",
        description:
          "Is the payment source for home care a Title program (for example, Title III, V, or XX)?",
      },
      M0150_CPAY_OTH_GOVT: {
        type: "boolean",
        description:
          "Is the payment source for home care another government source (for example, TriCare, VA)?",
      },
      M0150_CPAY_PRIV_INS: {
        type: "boolean",
        description: "Is the payment source for home care Private Insurance?",
      },
      M0150_CPAY_PRIV_HMO: {
        type: "boolean",
        description:
          "Is the payment source for home care a Private HMO/managed care plan?",
      },
      M0150_CPAY_SELFPAY: {
        type: "boolean",
        description: "Is the payment source for home care Self-pay?",
      },
      M0150_CPAY_OTHER: {
        type: "boolean",
        description:
          "Is the payment source for home care another source not listed?",
      },
      M0150_OtherValue: {
        type: "string",
        description:
          "If the payment source for home care is another source not listed, please specify.",
      },
      M0150_CPAY_UK: {
        type: "boolean",
        description: "Is the payment source for home care unknown?",
      },
    },
  },
  Administrative: {
    type: "object",
    options: {
      M0080_1: {
        type: "boolean",
        description:
          "Is the discipline of the person completing the assessment a Registered Nurse (RN)?",
      },
      M0080_2: {
        type: "boolean",
        description:
          "Is the discipline of the person completing the assessment a Physical Therapist (PT)?",
      },
      M0080_3: {
        type: "boolean",
        description:
          "Is the discipline of the person completing the assessment a Speech-Language Pathologist or Speech Therapist (SLP/ST)?",
      },
      M0080_4: {
        type: "boolean",
        description:
          "Is the discipline of the person completing the assessment an Occupational Therapist (OT)?",
      },
      M0090_INFO_COMPLETED_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description: "What is the date the assessment was completed?",
      },
      M0100_1: {
        type: "boolean",
        description:
          "Is this assessment being completed for the start of care with further visits planned?",
      },
      M0100_3: {
        type: "boolean",
        description:
          "Is this assessment being completed for the resumption of care after an inpatient stay?",
      },
      M0100_4: {
        type: "boolean",
        description:
          "Is this assessment being completed for a recertification (follow-up) reassessment?",
      },
      M0100_5: {
        type: "boolean",
        description:
          "Is this assessment being completed for another type of follow-up?",
      },
      M0100_6: {
        type: "boolean",
        description:
          "Is this assessment being completed because the patient was transferred to an inpatient facility but not discharged from the agency?",
      },
      M0100_7: {
        type: "boolean",
        description:
          "Is this assessment being completed because the patient was transferred to an inpatient facility and discharged from the agency?",
      },
      M0100_8: {
        type: "boolean",
        description:
          "Is this assessment being completed because of the patient's death at home?",
      },
      M0100_9: {
        type: "boolean",
        description:
          "Is this assessment being completed because the patient was discharged from the agency?",
      },
      M0102_PHYSN_ORDRD_SOCROC_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description:
          "What is the date of physician-ordered start of care or resumption of care?",
      },
      M0102_PHYSN_ORDRD_SOCROC_DT_NA: {
        type: "boolean",
        description:
          "Is there no specific start of care date ordered by the physician?",
      },
      c_m0102comment: {
        type: "string",
        description:
          "Do you have any comments regarding the physician-ordered start of care or resumption of care?",
      },
      M0104_PHYSN_RFRL_DT: {
        type: "string",
        pattern: "^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}$",
        description:
          "What is the date that the written or verbal referral for initiation or resumption of care was received by the Home Health Agency?",
      },
      c_m0104comment: {
        type: "string",
        description:
          "Do you have any comments regarding the referral date for initiation or resumption of care?",
      },
      M0110_01: {
        type: "boolean",
        description:
          "Is the Medicare home health payment episode for which this assessment will define a case mix group an 'early' episode in the patient's current sequence of adjacent Medicare home health payment episodes?",
      },
      M0110_02: {
        type: "boolean",
        description:
          "Is the Medicare home health payment episode for which this assessment will define a case mix group a 'later' episode in the patient's current sequence of adjacent Medicare home health payment episodes?",
      },
      M0110_03: {
        type: "boolean",
        description:
          "Is the timing of the Medicare home health payment episode for which this assessment will define a case mix group unknown?",
      },
      M0110_04: {
        type: "boolean",
        description:
          "Is this assessment not applicable for defining a Medicare case mix group because there is no Medicare case mix group to be defined by this assessment?",
      },
      A1250A: {
        type: "boolean",
        description:
          "Has lack of transportation kept you from medical appointments or from getting your medications?",
      },
      A1250B: {
        type: "boolean",
        description:
          "Has lack of transportation kept you from non-medical meetings, appointments, work, or from getting things that you need?",
      },
      A1250C: {
        type: "boolean",
        description: "Has lack of transportation not been an issue for you?",
      },
      A1250X: {
        type: "boolean",
        description:
          "Is the patient unable to respond to the question about transportation?",
      },
      A1250Y: {
        type: "boolean",
        description:
          "Did the patient decline to respond to the question about transportation?",
      },
      M1000_DC_LTC_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from a long-term nursing facility (NF) within the past 14 days?",
      },
      M1000_DC_SNF_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from a skilled nursing facility (SNF / TCU) within the past 14 days?",
      },
      M1000_DC_IPPS_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from a short-stay acute hospital (IPP S) within the past 14 days?",
      },
      M1000_DC_LTCH_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from a long-term care hospital (LTCH) within the past 14 days?",
      },
      M1000_DC_IRF_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from an inpatient rehabilitation hospital or unit (IRF) within the past 14 days?",
      },
      M1000_DC_PSYCH_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from a psychiatric hospital or unit within the past 14 days?",
      },
      M1000_DC_OTH_14_DA: {
        type: "boolean",
        description:
          "Was the patient discharged from another type of inpatient facility not listed above within the past 14 days?",
      },
      M1000_Other: {
        type: "string",
        description:
          "If the patient was discharged from another type of inpatient facility not listed above, please specify the type of facility.",
      },
      M1000_DC_NONE_14_DA: {
        type: "boolean",
        description:
          "Was the patient not discharged from any inpatient facility within the past 14 days?",
      },
      M1005_INP_DISCHARGE_DT: {
        type: "string",
        pattern: "MM/DD/YYYY",
        description: "What is the most recent inpatient discharge date?",
      },
      M1005_INP_DSCHG_UNKNOWN: {
        type: "boolean",
        description: "Is the inpatient discharge date unknown?",
      },
      c_m1005comment: {
        type: "string",
        description:
          "Can you provide details about the events leading to, and reasons for, the inpatient stay?",
      },
    },
  },
  Vitals: {
    type: "object",
    options: {
      cVS_pulseapical: {
        type: "string",
        description: "What was the patient's apical pulse?",
      },
      cVS_pulseradical: {
        type: "string",
        description: "What was the patient's radial pulse?",
      },
      cVS_height: {
        type: "string",
        description: "What is the patient's height in inches?",
      },
      cVS_weight: {
        type: "string",
        description: "What is the patient's weight?",
      },
      cVS_bpllying: {
        type: "string",
        description:
          "What was the patient's blood pressure while lying down on the left side?",
      },
      cVS_bplsitting: {
        type: "string",
        description:
          "What was the patient's blood pressure while sitting down on the left side?",
      },
      cVS_bplstanding: {
        type: "string",
        description:
          "What was the patient's blood pressure while standing on the left side?",
      },
      cVS_bprlying: {
        type: "string",
        description:
          "What was the patient's blood pressure while lying down on the right side?",
      },
      cVS_bprsitting: {
        type: "string",
        description:
          "What was the patient's blood pressure while sitting down on the right side?",
      },
      cVS_bprstanding: {
        type: "string",
        description:
          "What was the patient's blood pressure while standing on the right side?",
      },
      cVS_temperature: {
        type: "string",
        description: "What was the patient's temperature?",
      },
      cVS_respiratory: {
        type: "string",
        description: "What was the patient's respiratory rate?",
      },
      cActual1: {
        type: "boolean",
        description:
          "Was the patient's weight and height actual and measured by the nurse?",
      },
      cActual2: {
        type: "boolean",
        description:
          "Was the patient's weight and height stated by the patient?",
      },
      PulseApicalRegular1: {
        type: "boolean",
        description: "Was the patient's apical pulse regular?",
      },
      PulseApicalRegular2: {
        type: "boolean",
        description: "Was the patient's apical pulse irregular?",
      },
      PulseRadicalRegular1: {
        type: "boolean",
        description: "Was the patient's radial pulse regular?",
      },
      PulseRadicalRegular2: {
        type: "boolean",
        description: "Was the patient's radial pulse irregular?",
      },
    },
  },
};

export const testing_db_versions = {
  sales: {
    type: "object",
    description: "This is a table in a database with sales related columns",
    options: {
      id: {
        type: "int",
        info: "primary key",
      },
      sales_date: {
        type: "date",
        info: "format is YYYY-MM-DD",
      },
      product_id: {
        isContextRequired: true,
        type: "int",
        info: "foreign key that points to id of product table",
      },
      status: {
        isContextRequired: true,
        type: "ENUM",
        info: ["pending", "cancelled", "completed"],
      },
      user: {
        isContextRequired: true,
        type: "int",
        info: "foreign key that points to id of users table",
      },
      quantity_sold: {
        type: "int",
        info: "refers to the number of products sold",
      },
    },
  },
  users: {
    type: "object",
    description:
      "this is table in a database containing the info of the employees at the sales company",
    options: {
      id: {
        type: "int",
        info: "primary key",
      },
      first_name: {
        type: "string",
        info: "contains the first name of the employee, concatenate this with last_name to return query results regarding name",
      },
      last_name: {
        type: "string",
        info: "contains the last name of the employee, concatenate this with first_name to return query results regarding name",
      },
      phone: {
        type: "int",
        info: "format is in this way +31 648515052 with the first two digits after the plus indicating the area code",
      },
      email: {
        type: "string",
        info: "contains email of the employee",
      },
      job_type: {
        isContextRequired: true,
        type: "ENUM",
        info: ["sales", "recruitment"],
      },
      contract_signed: {
        type: "date",
        info: "the date the employee signed the contract",
      },
    },
  },
  leads: {
    type: "object",
    description:
      "These are the leads generated by the employees of the company for potential hires and recruitment",
    options: {
      id: {
        type: "int",
        info: "primary key",
      },
      first_name: {
        type: "string",
        info: "contains the first name of the lead, concatenate this with last_name to return query results regarding name",
      },
      last_name: {
        type: "string",
        info: "contains the last name of the lead, concatenate this with first_name to return query results regarding name",
      },
      phone: {
        type: "int",
        info: "format is in this way +31 648515052 with the first two digits after the plus indicating the area code",
      },
      email: {
        type: "string",
        info: "contains email of the lead",
      },
      department: {
        type: "string",
        info: "the branch where the lead was registered",
      },
      birth_date: {
        type: "date",
        info: "the birth date of the lead",
      },
      km_date: {
        type: "date",
        info: "the date of the interview for the lead",
        isContextRequired: true,
      },
      ws_date: {
        isContextRequired: true,
        type: "date",
        info: "the date of the workshop stage if the lead passed the interview stage (km_date)",
      },
      lead_date: {
        type: "date",
        info: "the date of that the lead was registered",
      },
      hire_date: {
        type: "date",
        info: "the date that the lead was hired after it passed the workshop training stage (ws_date)",
      },
      user: {
        isContextRequired: true,
        type: "int",
        info: "the user who acquired the lead, this is a foreign key that points to the id of the users table",
      },
    },
  },
  product: {
    type: "object",
    description:
      "this table contains information about the products that are sold in the company",
    options: {
      id: {
        type: "int",
        info: "this is a primary key",
      },
      name: {
        type: "string",
        info: "the name of the product",
      },
      client_id: {
        isContextRequired: true,
        type: "int",
        info: "foreign key that points to the id of the clients table",
      },
      price: {
        type: "int",
        info: "the price of the product",
      },
    },
  },
};

export const snowflake_db_versions = {
  CUSTOMER: {
    type: "object",
    description: "This is a table in a database with CUSTOMER related columns",
    options: {
      C_CUSTKEY: {
        type: "int",
        info: "The C_CUSTKEY column is a unique identifier for customers in a database. It serves as the primary key for the customer table.",
        isContextRequired: true,
      },
      C_NAME: {
        type: "varchar(50)",
        info: "C_NAME represents the name of the customer.",
      },
      C_ADDRESS: {
        type: "varchar(100)",
        info: "C_ADDRESS contains the address of the customer",
      },
      C_NATIONKEY: {
        type: "int",
        info: "C_NATIONKEY is a foreign key that links to the NATION table. It refers to the nation that the customer resides in.",
      },
      C_PHONE: {
        type: "int",
        info: "C_PHONE contains the phone number of the customer.",
      },
      C_ACCTBAL: {
        type: "int",
        info: "C_ACCTBAL is a column that stores the account balance of customers. It tracks the financial status of each customer by recording the amount of money the customer owes or has remaining in their account.",
      },
      C_MKTSEGMENT: {
        type: "varchar(20)",
        info: "C_MKTSEGMENT is a column that represents the marketing segment of a customer. It categorizes customers based on their purchasing behavior, demographics, and preferences.",
      },
      C_COMMENT: {
        type: "string",
        info: "This column is used to store comments",
      },
    },
  },
  LINEITEM: {
    type: "object",
    description: "This is a table in a database with LINEITEM related columns",
    options: {
      L_ORDERKEY: {
        type: "int",
        info: "L_ORDERKEY is a column that is a foreign key to the ORDERS table. It refers to the ORDER number of the item. This links to the O_ORDERKEY column",
      },
      L_PARTKEY: {
        type: "int",
        info: "L_PARTKEY is a column that is a foreign key to the PART table. It refers to the part number of the item. This links to the P_PARTKEY column",
      },
      L_SUPPKEY: {
        type: "int",
        info: "L_SUPPKEY is a column that is a foreign key to the SUPPLIER table. It refers to the SUPPLIER number of the item. This links to the S_SUPPKEY column",
      },
      L_LINENUMBER: {
        type: "int",
        info: "L_LINENUMBER is a column that stores the line number associated with a lineitem.",
      },
      L_QUANTITY: {
        type: "int",
        info: "The L_QUANTITY column refers to the quantity of the lineitem.",
      },
      L_EXTENDEDPRICE: {
        type: "float",
        info: "L_EXTENDEDPRICE is a column that stores the price of the lineitem",
      },
      L_DISCOUNT: {
        type: "float",
        info: "The L_DISCOUNT column represents the discount percentage applied to the lineitem.",
      },
      L_TAX: {
        type: "float",
        info: "L_TAX refers to the tax on the lineitem.",
      },
      L_RETURNFLAG: {
        type: "ENUM",
        info: "L_RETURNFLAG is a column that represents the return status of the lineitem, the options are: 'A' which refers to awaiting return,'N' which refers to not returned and 'R' which refers to returned.",
      },
      L_LINESTATUS: {
        type: "ENUM",
        info: "the l_linestatus column represents the status of a line item in an order.the options are 'F' shipped (fully delivered) and 'O' on hold",
      },
      L_SHIPDATE: {
        type: "DATE",
        info: "L_SHIPDATE is a column that stores the date on which lineitems are scheduled to be shipped.",
      },
      L_COMMITDATE: {
        type: "DATE",
        info: "L_COMMITDATE column represents the date on which a transaction associated with a line item was committed. This column is used to track the timing of when an order was finalized or confirmed.",
      },
      L_RECEIPTDATE: {
        type: "DATE",
        info: "L_RECEIPTDATE is a column that stores the dates on which receipts were generated or received for certain transactions or activities.",
      },
      L_SHIPINSTRUCT: {
        type: "string",
        info: "L_SHIPINSTRUCT is a column that stores the shipping instructions for a particular order. It contains textual information provided by the customer or specified by the company, detailing how the order should be handled, packaged, and delivered.",
      },
      L_SHIPMODE: {
        type: "ENUM",
        info: "L_SHIPMODE column represents the shipping mode or method used for delivering items associated with a line item in an order. This column provides information about how items were shipped from the supplier to the customer. The options are 'AIR', 'REG AIR', 'MAIL', 'RAIL','SHIP' and 'FOB'",
      },
      L_COMMENT: {
        type: "string",
        info: "The L_COMMENT column stores additional comments",
      },
    },
  },
  NATION: {
    type: "object",
    description: "This is a table in a database with NATION related columns",
    options: {
      N_NATIONKEY: {
        type: "int",
        info: "The N_NATIONKEY column is a primary key.",
      },
      N_NAME: {
        type: "varchar(50)",
        info: "N_NAME contains the names of the nations.",
      },
      N_REGIONKEY: {
        type: "int",
        info: "The N_REGIONKEY column is a foreign key that points to the REGION table, it links to the R_REGIONKEY column.",
      },
      N_COMMENT: {
        type: "string",
        info: "The N_COMMENT column stores textual feedback or comments.",
      },
    },
  },
  ORDERS: {
    type: "object",
    description: "This is a table in a database with ORDERS related columns",
    options: {
      O_ORDERKEY: {
        type: "int",
        info: "The O_ORDERKEY column is a primary key.",
      },
      O_CUSTKEY: {
        type: "int",
        info: "The O_CUSTKEY column is a foreign key that links to the CUSTOMERS table, it can be joined on the C_CUSTKEY column.",
      },
      O_ORDERSTATUS: {
        type: "ENUM",
        info: "This column indicates the current state of an order, such as whether the order has been shipped or is still pending. The options are 'F' order has been filled, 'O' order is on hold, 'P' order is in progress.",
      },
      O_TOTALPRICE: {
        type: "float",
        info: "This column stores the cumulative price of all the line items within an order, including any applicable taxes or discounts",
      },
      O_ORDERDATE: {
        type: "Date",
        info: "The O_ORDERDATE column represents the date on which a particular order was placed.",
      },
      O_ORDERPRIORITY: {
        type: "ENUM",
        info: "This column indicates the urgency or importance of an order within a business context. The options are '1' refers to URGENT, '2' means HIGH, '3' means MEDIUM, '4' NOT SPECIFIED, '5' means LOW.",
      },
      O_CLERK: {
        type: "varchar(50)",
        info: "This column provides information about the individual responsible for processing the order and is typically associated with the person who interacts with customers and manages the order entry process",
      },
      O_SHIPPRIORITY: {
        type: "ENUM",
        info: "O_SHIPPRIORITY is a database column that reflects the priority level assigned to a shipment.",
      },
      O_COMMENT: {
        type: "string",
        info: "O_COMMENT is a column that stores additional comments.",
      },
    },
  },
  PART: {
    type: "object",
    description: "This is a table in a database with PART related columns",
    options: {
      P_PARTKEY: {
        type: "int",
        info: "P_PARTKEY is a column in the database that represents the primary key for a specific part in a system.",
      },
      P_NAME: {
        type: "varchar(50)",
        info: "The P_NAME column refers to the name of a product.",
      },
      P_MFGR: {
        type: "varchar(50)",
        info: "The P_MFGR column in the database represents the manufacturer of a particular product.",
      },
      P_BRAND: {
        type: "varchar(50)",
        info: "P_BRAND is a column in the database that stores the brand name of a product",
      },
      P_TYPE: {
        type: "varchar(50)",
        info: "The P_TYPE column represents the type or category of a certain product.",
      },
      P_SIZE: {
        type: "varchar(50)",
        info: "The column P_SIZE represents the size of a product. It is used to store numerical data indicating the dimensions or physical measurements of a product, such as length, width, or volume.",
      },
      P_CONTAINER: {
        type: "ENUM",
        info: "The P_CONTAINER column can have various values representing different container types or packaging codes. Examples of possible values include: 'SM CASE', 'SM BOX', 'SM PACK', 'MED BAG', 'MED BOX', 'MED PKG', 'LG BOX', 'LG PACK', 'LG CASE', 'JUMBO BOX'",
      },
      P_RETAILPRICE: {
        type: "float",
        info: "P_RETAILPRICE is a database column that stores the retail price of a product.",
      },
      P_COMMENT: {
        type: "string",
        info: "The P_COMMENT column is used to store additional comments",
      },
    },
  },
  PARTSUPP: {
    type: "object",
    description: "This is a table in a database with PARTSUPP related columns",
    options: {
      PS_PARTKEY: {
        type: "int",
        info: "The PS_PARTKEY column is a foreign key that points to the PART table, it links to the P_PARTKEY column.",
      },
      PS_SUPPKEY: {
        type: "int",
        info: "The PS_SUPPKEY column is a foreign key that points to the SUPPLIER table, it links to the S_SUPPKEY column.",
      },
      PS_AVAILQTY: {
        type: "int",
        info: "The column PS_AVAILQTY in the database represents the available quantity of a product.",
      },
      PS_SUPPLYCOST: {
        type: "float",
        info: " the PS_SUPPLYCOST column represents the cost that a supplier incurs to supply a part.",
      },
      PS_COMMENT: {
        type: "string",
        info: "PS_COMMENT is a column that allows users to add additional information or comments.",
      },
    },
  },
  REGION: {
    type: "object",
    description: "This is a table in a database with REGION related columns",
    options: {
      R_REGIONKEY: {
        type: "int",
        info: "R_REGIONKEY is a primary key for the REGION table.",
      },
      R_NAME: {
        type: "ENUM",
        info: "R_NAME refers to the name of the region, the options are 'ASIA', 'MIDDLE EAST', 'AFRICA', 'AMERICA' and 'EUROPE'",
      },
      R_COMMENT: {
        type: "string",
        info: "The R_COMMENT column is used to store textual comments or additional information.",
      },
    },
  },
  SUPPLIER: {
    type: "object",
    description: "This is a table in a database with SUPPLIER related columns",
    options: {
      S_SUPPKEY: {
        type: "int",
        info: "S_SUPPKEY is a primary key for the SUPPLIER table.",
      },
      S_NAME: {
        type: "varchar(50)",
        info: "The S_NAME column stores the names of the suppliers.",
      },
      S_ADDRESS: {
        type: "varchar(50)",
        info: "S_ADDRESS stores the addresses of the suppliers.",
      },
      S_NATIONKEY: {
        type: "int",
        info: "S_NATIONKEY is a foreign key that links to the NATION table. it is linked to the N_NATIONKEY column.",
      },
      S_PHONE: {
        type: "int",
        info: "S_PHONE is a column in a database table that stores the phone number associated with the SUPPLIER",
      },
      S_ACCTBAL: {
        type: "float",
        info: "S_ACCTBAL is a column in a database table that stores the account balance of the SUPPLIERS.",
      },
      S_COMMENT: {
        type: "string",
        info: "S_COMMENT is a column in the database that is used to store additional comments or notes,",
      },
    },
  },
};
