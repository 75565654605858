import axios from "axios";
import { store } from "../store";
import { clearAuthUser } from "../storage/AuthUserSlice";

console.log(process.env.REACT_APP_API_BASE_URL);
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 120000, // Timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    // Add any other default headers you need
  },
});

axiosInstance.interceptors.request.use((config) => {
  const user = store.getState().authUser.authUser;
  if (!!user) {
    const { accessToken } = user;
    // TODO: discuss with Hamza, why getContexts call need a different Authorization token
    // Can we change the api to authorize user based on JWT
    config.headers.Authorization =
      config.headers.Authorization || `Bearer ${accessToken}`;
  }

  return config;
});

const defaultResponseFormatter = (response) => {
  const {
    data,
    config: { url },
    status,
  } = response;
  return { data, url, status };
};
const responseFormatters = {
  get: defaultResponseFormatter,
  post: defaultResponseFormatter,
};

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const {
        config: { method },
      } = response;
      const formatter = responseFormatters[method];
      if (formatter) {
        return formatter(response);
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(clearAuthUser());
    }
    return Promise.reject(error);
  }
);

// Create a service object to handle API calls
const apiService = {
  get: (url, config) => axiosInstance.get(url, config),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  patch: (url, data, config) => axiosInstance.patch(url, data, config),
  delete: (url, config) => axiosInstance.delete(url, config),
};

export default apiService;
