import React, { useEffect, useRef } from "react";
import "../../styles.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const ChatInfoCard = ({ infoCard }) => {
  const YAML = require("json-to-pretty-yaml");

  var infoObj = JSON.parse(infoCard);
  const data = YAML.stringify(infoObj);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [infoCard]);

  return (
    <div ref={ref} className="infoCardContent">
      <Card className="mainCard">
        <CardContent
          style={{ border: "1px solid", backgroundColor: "#e5e9ec" }}
        >
          <pre>
            <code className="pre"> {data} </code>
          </pre>
        </CardContent>
      </Card>
    </div>
  );
};

export default ChatInfoCard;
