/* eslint-disable eqeqeq */
import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles.scss";
import "./loginstyles.scss";
import { useDispatch } from "react-redux";
import { changeAuthUser } from "../../storage/AuthUserSlice";
import logoImage from "../../imgs/Grayscale_on_Transparent.png";
import { tokens } from "../../theme";
import ReactGA from "react-ga";
import getMessageForErrorCode from "../../common/errorCodes";
import apiService from "../../services/apiService";

const Login = () => {
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [loggedInEmail, setLoggedInEmail] = useState("");
  const [validateMode, setValidateMode] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const handleLoginCodeSubmit = async (e) => {
    e.preventDefault();
    setErrMessage("");
    setErr(false);
    const loginCode = e.target[0].value;
    setButtonDisabled(true);

    apiService
      .post("/api/v1.0/Authentication/Verify", {
        identity: loggedInEmail,
        code: loginCode,
      })
      .then((response) => {
        let { data } = response;
        // handle the data here
        if (data.errorCode != 0) {
          setErrMessage(getMessageForErrorCode(data.errorCode));
          setErr(true);
          setButtonDisabled(false);
          return;
        }
        dispatch(changeAuthUser(data.data));
        navigate("/dashboard");
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });

    setButtonDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    setErrMessage("");
    setErr(false);
    setButtonDisabled(true);

    apiService
      .post("/api/v1.0/Authentication/SignIn", {
        identity: email,
      })
      .then((response) => {
        let { data } = response;
        // handle the data here
        if (data.errorCode != 0) {
          setErrMessage(getMessageForErrorCode(data.errorCode));
          setErr(true);
          setButtonDisabled(false);
          return;
        }
        setLoggedInEmail(email);
        setValidateMode(true);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });

    setButtonDisabled(false);
  };

  return (
    <div className="d-lg-flex half">
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <Box display="flex">
                <h3 style={{ display: "flex" }}>
                  Login
                  <a
                    href="https://www.aiembodied.com"
                    style={{
                      display: "flex",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      width: "150px",
                      height: "40px",
                    }}
                  >
                    <img src={logoImage} alt="logo" />
                  </a>
                </h3>
              </Box>
              {/* <p className="mb-4">
                Don't have an account? <Link to={"/signup"}>Signup</Link> here.
              </p> */}
              {!validateMode && (
                <form onSubmit={handleSubmit}>
                  <div className="form-group first">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="your-email@email.com"
                      id="email"
                    />
                  </div>

                  <div className="d-flex mb-5 align-items-center">
                    {err && <span>{errMessage}</span>}
                  </div>

                  <input
                    type="submit"
                    disabled={buttonDisabled}
                    value="Log In"
                    style={{
                      backgroundColor: colors.primary[200],
                      border: "none",
                    }}
                    className="btn btn-block btn-primary"
                  />
                </form>
              )}
              {validateMode && (
                <form onSubmit={handleLoginCodeSubmit}>
                  <div className="form-group first">
                    <label htmlFor="loginCode">Login Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="loginCode"
                    />
                  </div>

                  <div className="d-flex mb-5 align-items-center">
                    {err && <span>{errMessage}</span>}
                  </div>

                  <input
                    type="submit"
                    value="Log In"
                    disabled={buttonDisabled}
                    style={{
                      backgroundColor: colors.primary[200],
                      border: "none",
                    }}
                    className="btn btn-block btn-primary"
                  />
                </form>
              )}
              <p className="mb-4" style={{ fontSize: 12 }}>
                *Reach out to us at contact@aiembodied.com for any issues
                related to sign up or login.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
