import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "../../styles.scss";
import { useDispatch } from "react-redux";
import { addMessage } from "../../storage/MessagesSlice";
import { addInfoCard } from "../../storage/InfoCardSlice";
import IconButton from "@mui/material/IconButton";
import { resourceTree } from "../graph/ReactFlowToResourceGraph";
import { useLocation } from "react-router-dom";

function generateTransactionID() {
  return Math.random().toString(36).substring(2);
}

const ChatInput = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  //console.log(jsonToSend);
  const [text, setText] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const location = useLocation();

  const handleSend = async () => {
    // console.log(text);
    dispatch(
      addMessage({
        id: 1,
        text: text,
        sender: "input",
      })
    );

    var resourceGraph = null;

    if (location.state) {
      if (location.state.resourceGraph) {
        resourceGraph = location.state.resourceGraph;
      }
      if (location.state.reactFlowGraph) {
        resourceGraph = resourceTree(location.state.reactFlowGraph);
        console.log("REACT FLOW GRAPH");
        console.log(location.state.reactFlowGraph);
      }
    }
    console.log("RESOURCE GRAPH");
    console.log(resourceGraph);

    if (resourceGraph != null) {
      //jsonToSend.text = text;
      const inputNode = resourceGraph.resources.find(
        (resource) => resource.type === "input"
      );
      if (inputNode) {
        inputNode.value = text;
      }
      console.log(resourceGraph);

      resourceGraph["transaction_id"] = generateTransactionID();
      // creates entity
      fetch("http://127.0.0.1:8000/inference", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 49noifn345g0540nf40",
          //accept: "application/json"
          //authorization: "bearer 49noifn345g0540nf40",
        },
        body: JSON.stringify(resourceGraph),
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch(addInfoCard(JSON.stringify(response, undefined, 2)));

          var responseText = "Error Retrieving Output - Contact Support";
          for (var value of Object.values(response.resources)) {
            if (value.name === "output") {
              responseText = value.output;
            }
          }

          dispatch(
            addMessage({
              id: 2,
              text: responseText,
              sender: "output",
            })
          );
          setText("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="chatInput">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        style={{
          backgroundColor: colors.primary[400],
          color: colors.grey[100],
        }}
        value={text}
      />
      <div className="send">
        <IconButton
          aria-label="show"
          align="right"
          onClick={() => handleSend()}
        >
          {/* <SendIcon  sx={{ color: colors.primary[400], fontSize: "30px" }}/> */}
        </IconButton>
      </div>
    </div>
  );
};

export default ChatInput;
