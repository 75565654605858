import { useCallback } from "react";
import { Box, Card, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";

const defaultDescription = "Add description for this column";

const FieldViewerCardList = ({
  fields,
  draggingEle,
  tableErrors,
  onEdit,
  onDelete,
  handleEleDrop,
}) => {
  const handleOnEdit = useCallback(
    (fieldData) => {
      const { info, isContextRequired = false } = fieldData;
      onEdit({ ...fieldData, info: isContextRequired ? "" : info });
    },
    [onEdit]
  );
  const handleOnDelete = useCallback(
    (fieldName) => {
      onDelete(fieldName);
    },
    [onDelete]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {Object.entries(fields).map(([fieldName, fieldType]) => {
        const {
          type,
          info,
          isContextRequired = false,
          pattern = "",
        } = fieldType;

        const hasError = tableErrors[fieldName];
        const key = `table-column-${fieldName}`;
        const description = info || fieldType.description;
        const isSamePatternTypeDragging =
          draggingEle?.componentType === "prop" && draggingEle?.type === type;
        let borderColor = isContextRequired ? "warning" : "success";
        if (hasError) {
          borderColor = "danger";
        }
        return (
          <Card
            key={key}
            className={classNames(
              `d-md-flex border rounded-0 border-${borderColor} p-2`,
              { "bg-secondary": isSamePatternTypeDragging }
            )}
            onDragOverCapture={(e) => e.preventDefault()}
            onDrop={(e) => handleEleDrop(e, type, fieldName)}
          >
            <Box
              sx={{
                display: "flex",
                flex: 9,
                gap: 1,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography sx={{ wordWrap: "break-word" }}>
                name: {fieldName}
              </Typography>
              <Typography>
                description:{" "}
                {isContextRequired ? defaultDescription : description}
              </Typography>
              {!!pattern && <Typography>output format: {pattern}</Typography>}
              {!!hasError && <Typography color="error">{hasError}</Typography>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <IconButton
                sx={{ height: 50 }}
                onClick={() =>
                  handleOnEdit({
                    name: fieldName,
                    info: description,
                    ...fieldType,
                  })
                }
              >
                <EditIcon size="small" />
              </IconButton>
              <IconButton
                sx={{ height: 50 }}
                onClick={() => handleOnDelete(fieldName)}
              >
                <DeleteIcon size="small" />
              </IconButton>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};

export default FieldViewerCardList;
