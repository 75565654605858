import { Typography } from "@mui/material";

const ConfirmationModalBody = ({
  confirmMessage = "Are you sure?",
  confirmBtnText = "confirm",
  cancelBtnText = "cancel",
  onConfirmHandler = () => {},
  onCancelHandler = () => {},
}) => (
  <>
    <Typography>{confirmMessage}</Typography>
    <button
      onClick={onConfirmHandler}
      className="btn btn-square rounded-0 btn-light btn-outline-dark"
    >
      {confirmBtnText}
    </button>
    <button
      onClick={onCancelHandler}
      className="btn btn-square rounded-0 btn-dark"
    >
      {cancelBtnText}
    </button>
  </>
);

export default ConfirmationModalBody;
