import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getContexts } from "../../http/contextData";
import { applyFormExtensions } from "../formBuilder/utils";
import { Typography } from "@mui/material";
import FieldGroup from "../../components/FieldGroup";

const schemaFieldGroup = {
  schema: {
    type: "select",
    label: "Schema",
    options: {},
  },
  version: {
    type: "select",
    label: "Version",
    options: {},
  },
  tables: {
    type: "multiselect",
    label: "Tables",
    options: [],
  },
};

// TODO: Try to find a better way to save and set values for multiselect type fields
const getState = (fieldGroup, data) =>
  Object.keys(fieldGroup).reduce((acc, crr) => {
    // Below if condition is only for multiselect type field.
    // Need to revamp
    if (typeof data[crr] === "object") {
      acc[crr] = Object.keys(data[crr]);
    } else {
      acc[crr] = data[crr] || "";
    }
    return acc;
  }, {});

const PromptTabPanel = ({ data = {}, onAddSchema = () => {} }) => {
  const userId = useSelector((state) => state.authUser.authUser.id);
  const [isUserSchemasLoading, setIsUserSchemasLoading] = useState(false);
  const [userSchemas, setUserSchemas] = useState([]);
  const [state, setState] = useState(() => getState(schemaFieldGroup, data));

  useEffect(() => {
    if (!!userId) {
      setIsUserSchemasLoading(true);
      getContexts(userId).then(({ data, error }) => {
        if (!!data) {
          setUserSchemas(data);
        }
        if (!!error) {
          // TODO: show notification
          console.log("error=> ", error);
        }
        setIsUserSchemasLoading(false);
      });
    }
  }, [userId]);

  const { schemaOptions, versionOptionsBySchema, tableOptionsBySchema } =
    useMemo(
      () =>
        userSchemas.reduce(
          (acc, { id, schemas }) => {
            acc.schemaOptions[id] = `${id.slice(0, id.indexOf("_"))}`;
            acc.versionOptionsBySchema[id] = schemas.reduce(
              (a, { schema: { version, description } }) => {
                a[version] = description;
                return a;
              },
              {
                [Symbol.iterator]: function* () {
                  yield* Object.keys(this);
                },
              }
            );
            acc.tableOptionsBySchema[id] = Object.keys(schemas[0].tables);
            return acc;
          },
          {
            schemaOptions: {
              [Symbol.iterator]: function* () {
                yield* Object.keys(this);
              },
            },
            versionOptionsBySchema: {},
            tableOptionsBySchema: {},
          }
        ),
      [userSchemas]
    );

  // to set initial values after fetching userSchemas
  useEffect(() => {
    if (!!userSchemas.length && !state.schema) {
      const [defaultSchemaOption] = schemaOptions;
      const [defaultSchemaVersion] =
        versionOptionsBySchema[defaultSchemaOption];
      const defaultTables = tableOptionsBySchema[defaultSchemaOption];
      setState((prevState) => ({
        ...prevState,
        schema: defaultSchemaOption,
        version: defaultSchemaVersion,
        tables: defaultTables,
      }));
    }
  }, [
    state.schema,
    userSchemas,
    schemaOptions,
    versionOptionsBySchema,
    tableOptionsBySchema,
  ]);

  const getDerivedState = useCallback(
    (state, name, value) => {
      const stateHandlers = {
        schema: (value) => {
          const [defaultVersion] = versionOptionsBySchema[value];
          const defaultTables = tableOptionsBySchema[value];
          return { version: defaultVersion, tables: defaultTables };
        },
      };

      const newState = {
        [name]: value,
        ...(stateHandlers?.[name]?.(value) || {}),
      };
      return newState;
    },
    [versionOptionsBySchema, tableOptionsBySchema]
  );

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setState((prevState) => ({
        ...prevState,
        ...getDerivedState(prevState, name, value),
      }));
    },
    [getDerivedState]
  );

  const handleAddSchema = useCallback(() => {
    const schema = userSchemas.find(({ id }) => id === state.schema);
    const version = schema.schemas.find(
      ({ schema: { version } }) => +version === +state.version
    );
    const tables = state.tables.reduce((acc, crr) => {
      acc[crr] = version.tables[crr];
      return acc;
    }, {});

    onAddSchema({ ...state, tables });
  }, [state, userSchemas, onAddSchema]);

  const fieldExtensions = useMemo(() => {
    return {
      schema: (props) => ({
        ...props,
        options: schemaOptions,
      }),
      version: (props) => ({
        ...props,
        options: versionOptionsBySchema[state.schema] || {},
      }),
      tables: (props) => ({
        ...props,
        options: tableOptionsBySchema[state.schema] || [],
      }),
    };
  }, [
    schemaOptions,
    versionOptionsBySchema,
    tableOptionsBySchema,
    state.schema,
  ]);

  const fieldGroup = useMemo(
    () => applyFormExtensions(schemaFieldGroup, fieldExtensions),
    [fieldExtensions]
  );

  if (isUserSchemasLoading) {
    return <>...loading</>;
  }
  if (!userSchemas.length) {
    return <Typography>There is no version saved for any schema</Typography>;
  }
  return (
    <>
      <FieldGroup fields={fieldGroup} state={state} onChange={onChange} />
      <button
        className="btn btn-square btn-dark mt-2"
        onClick={handleAddSchema}
      >
        Add Schema
      </button>
    </>
  );
};

export default PromptTabPanel;
