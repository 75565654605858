const TabPanel = ({
  id,
  value,
  index,
  labelledBy,
  children,
  className,
  ...props
}) => {
  return (
    value === index && (
      <div
        role="tabpanel"
        id={id}
        aria-labelledby={labelledBy}
        className={className}
        {...props}
      >
        {children}
      </div>
    )
  );
};

export default TabPanel;
