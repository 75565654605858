import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Stack from "@mui/material/Stack";

const StatBox = ({ title, subtitle, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px" flexWrap="wrap" overflow="hidden">
      <Box display="grid" gridTemplateColumns="repeat(13, 1fr)">
        <Box
          gridColumn="span 10"
          // component={Stack}
          // direction="column"
          justifyContent="start"
        >
          <Box display="flex">
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {title}
            </Typography>
          </Box>
          <Box display="flex" mt="2px">
            <Typography variant="h5" sx={{ color: colors.grey[200] }}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Box gridColumn="span 3" margin="auto">
          <Box
            component={Stack}
            direction="column"
            justifyContent="center"
            backgroundColor={colors.primary[200]}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
            }}
          >
            <div style={{ margin: "auto" }}>{icon}</div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
