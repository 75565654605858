import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeAuthUser } from "../../storage/AuthUserSlice";
import "../../styles.scss";
import "../login/loginstyles.scss";
import background from "../../imgs/undraw_handcrafts_tree.svg";
import logoImage from "../../imgs/Grayscale_on_Transparent.png";
import { tokens } from "../../theme";
import ReactGA from "react-ga";
import getMessageForErrorCode from "../../common/errorCodes";
import apiService from "../../services/apiService";

const Signup = () => {
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [validateMode, setValidateMode] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const handleLoginCodeSubmit = async (e) => {
    e.preventDefault();
    setErrMessage("");
    setErr(false);
    const loginCode = e.target[0].value;
    setButtonDisabled(true);

    apiService
      .post("/api/v1.0/Authentication/Verify", {
        identity: registeredEmail,
        code: loginCode,
      })
      .then((response) => {
        let { data } = response;
        // handle the data here
        if (data.errorCode != 0) {
          setErrMessage(getMessageForErrorCode(data.errorCode));
          setErr(true);
          setButtonDisabled(false);
          return;
        }

        dispatch(changeAuthUser(data.data));
        navigate("/dashboard");
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });

    setButtonDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const confirmEmail = e.target[1].value;
    setErrMessage("");
    setErr(false);
    setButtonDisabled(true);

    apiService
      .post("/api/v1.0/Authentication/Register", {
        identity: email,
        confirmIdentity: confirmEmail,
        name: e.target[2].value,
        // company: e.target[3].value,
        ContactType: 0,
      })
      .then((response) => {
        let { data } = response;
        // handle the data here
        if (data.errorCode != 0) {
          setErrMessage(getMessageForErrorCode(data.errorCode));
          setErr(true);
          setButtonDisabled(false);
          return;
        }
        setRegisteredEmail(email);
        setValidateMode(true);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    setButtonDisabled(false);
  };

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-1 order-md-2"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: "100%",
          margin: "20px",
        }}
      ></div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <Box display="flex">
                <h3 style={{ display: "flex" }}>
                  Sign Up
                  <a
                    href="https://www.aiembodied.com"
                    style={{
                      display: "flex",
                      backgroundImage: `url(${logoImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      width: "150px",
                      height: "40px",
                    }}
                  ></a>
                </h3>
              </Box>
              <p className="mb-4">
                Already have an account? <Link to={"/login"}>Login</Link> here.
              </p>

              {!validateMode && (
                <form onSubmit={handleSubmit}>
                  <div className="form-group first">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="your-email@email.com"
                      id="email"
                    />
                  </div>
                  <div className="form-group last mb-3">
                    <label htmlFor="confirmEmail">Confirm Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Confirm Email"
                      id="confirmEmail"
                    />
                  </div>
                  <div className="form-group last mb-3">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      id="name"
                    />
                  </div>

                  <div className="d-flex mb-5 align-items-center">
                    {err && <span>{errMessage}</span>}
                  </div>
                  <input
                    disabled={buttonDisabled}
                    type="submit"
                    value="Sign Up"
                    style={{
                      backgroundColor: colors.primary[200],
                      border: "none",
                    }}
                    className="btn btn-block btn-primary"
                  />
                </form>
              )}
              {validateMode && (
                <form onSubmit={handleLoginCodeSubmit}>
                  <div className="form-group first">
                    <label htmlFor="loginCode">Login Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="loginCode"
                    />
                  </div>
                  <div className="d-flex mb-5 align-items-center">
                    {err && <span>{errMessage}</span>}
                  </div>

                  <input
                    type="submit"
                    disabled={buttonDisabled}
                    value="Complete Sign Up"
                    style={{
                      backgroundColor: colors.primary[200],
                      border: "none",
                    }}
                    className="btn btn-block btn-primary"
                  />
                </form>
              )}
              <p className="mb-4" style={{ fontSize: 12 }}>
                *Reach out to us at contact@aiembodied.com for any issues
                related to sign up or login.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
