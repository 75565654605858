import { Grid } from "@mui/material";

import { fieldTypes } from "./constants";

const FieldsTabPanel = ({ handleDragStart, handleDragEnd }) => {
  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      justifyContent="flex-start"
      paddingTop={2}
      spacing={2}
    >
      {fieldTypes.map((item, index) => (
        <Grid key={`${index}-${item}`} item xs={6}>
          <div
            onDragStart={(e) =>
              handleDragStart(e, { componentType: "field", type: item })
            }
            draggable="true"
            onDragEnd={handleDragEnd}
            className="dndnode input"
          >
            {item}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default FieldsTabPanel;
