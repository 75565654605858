export const fieldTypes = ["text", "date", "ENUM"];

export const fieldsPattern = [
  { type: "date", patterns: ["HH:MM", "SS:HH:MM", "MM/DD/YYYY", "DD/MM/YYYY"] },
  { type: "string", patterns: ["xxx@xx.xx", "xxx-xx-xxxx", "xx-xxxxxxx"] },
];

// { date: [...dateTypes], string: [...stringTypes]}
export const optionsByType = fieldsPattern.reduce((acc, { type, patterns }) => {
  acc[type] = patterns;
  return acc;
}, {});
