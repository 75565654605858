/* eslint-disable eqeqeq */
export default function getMessageForErrorCode(errorCode) {
  if (errorCode == 30035) {
    return "User already exists";
  }

  if (errorCode == 30005) {
    return "Invalid data";
  }

  return "Unknown error code: " + errorCode;
}
