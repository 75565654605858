import apiService from "../services/apiService";

const base_url = "https://0q29w0g7h8.execute-api.us-east-2.amazonaws.com";

const route = "/context-data";
const headers = { Authorization: "inLHCLQPG0" };
const config = {
  baseURL: base_url,
  headers,
};

export const getContexts = (userId = "001") =>
  apiService.get(route + `?user_id=${userId}`, config);

export const getContext = (userId, id) =>
  apiService.get(route + `?user_id=${userId}&id=${id}`, config).then((resp) => {
    const { data } = resp;
    return { data };
  });

export const postContext = (
  data = {
    user_id: "001",
    id: "55c",
    schema: { a: "a" },
  }
) => apiService.post(route, data, config);

export const deleteContext = ({ userId = "001", contextId = "002" } = {}) =>
  apiService.delete(route + `?user_id=${userId}&id=${contextId}`, config);
